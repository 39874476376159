import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import thatchLogo from '../assets/ThatchLogo.svg';
import thatchLogoMini from '../assets/ThatchLogoGreen.svg';

export default function ThatchLogo(props) {
    const { mini, svg, sx } = props;

    if (svg) {
        return (
            <Box
                component="svg"
                sx={sx}
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 863.49 146.9">
                <defs>
                    <style
                        dangerouslySetInnerHTML={{
                            __html: `
                                .cls-3, .cls-4 {
                                    stroke-width: 0px;
                                }
                                .cls-3 {
                                    fill: #fff;
                                }
                                .cls-4 {
                                    fill: currentColor;
                                }`,
                        }}
                    />
                </defs>
                <circle className="cls-3" cx="73.76" cy="73.83" r="65.36" />
                <g>
                    <path
                        className="cls-4"
                        d="M177.39,110.9v-52.88h-15.82v-28.04h15.82V1.03h29.26v28.95h23.84v28.04h-23.84v51.36c0,7.15,3.47,10.4,9.1,10.4h12.14v26.01h-16.04c-22.32,0-34.46-10.4-34.46-34.89Z"
                    />
                    <path
                        className="cls-4"
                        d="M251.08,1.03h29.26v47.67c6.07-12.14,17.12-18.21,34.03-18.21,24.27,0,42.91,17.99,42.91,46.38v68.92h-29.26v-61.77c0-19.5-9.75-27.96-24.27-27.96-12.35,0-23.41,8.45-23.41,27.96v61.77h-29.26V1.03Z"
                    />
                    <path
                        className="cls-4"
                        d="M380.03,86.37c0-32.51,25.06-56.38,56.05-56.38,19.51,0,29.34,5.23,35.41,17.37v-17.77h29.04v116.21h-28.39v-21.94c-6.07,12.79-16.25,21.24-36.41,21.24-30.99,0-55.7-26.22-55.7-58.73ZM440.5,118.66c19.29,0,31.64-14.74,31.64-32.29s-12.35-32.73-31.64-32.73-31.21,14.95-31.21,32.51,12.57,32.51,31.21,32.51Z"
                    />
                    <path
                        className="cls-4"
                        d="M536.94,110.9v-52.88h-15.82v-28.04h15.82V1.03h29.26v28.95h23.84v28.04h-23.84v51.36c0,7.15,3.47,10.4,9.1,10.4h12.14v26.01h-16.04c-22.32,0-34.46-10.4-34.46-34.89Z"
                    />
                    <path
                        className="cls-4"
                        d="M731.99,1.03h29.26v47.67c6.07-12.14,17.12-18.21,34.03-18.21,24.27,0,42.91,17.99,42.91,46.38v68.92h-29.26v-61.77c0-19.5-9.75-27.96-24.27-27.96-12.35,0-23.41,8.45-23.41,27.96v61.77h-29.26V1.03Z"
                    />
                    <path
                        className="cls-4"
                        d="M603.8,88.02c0-7.94,1.54-15.43,4.61-22.47,3.07-7.04,7.24-13.19,12.49-18.44,5.25-5.25,11.43-9.39,18.53-12.41,7.1-3.02,14.67-4.53,22.72-4.53,7.26,0,14.08,1.12,20.45,3.35,6.37,2.24,12.04,5.37,17.02,9.39,4.97,4.02,9.14,8.83,12.49,14.42,3.35,5.59,5.64,11.74,6.87,18.44h-28.67c-1.9-6.04-5.37-10.84-10.4-14.42-5.03-3.58-10.9-5.37-17.6-5.37-4.36,0-8.44.84-12.24,2.51-3.8,1.68-7.13,3.97-9.98,6.87-2.85,2.91-5.09,6.29-6.71,10.14-1.62,3.86-2.43,7.96-2.43,12.32s.81,8.66,2.43,12.58c1.62,3.91,3.86,7.32,6.71,10.23,2.85,2.91,6.18,5.2,9.98,6.87,3.8,1.68,7.88,2.51,12.24,2.51,6.71,0,12.58-1.79,17.6-5.37,5.03-3.58,8.49-8.38,10.4-14.42h28.67c-1.23,6.71-3.52,12.86-6.87,18.44-3.35,5.59-7.52,10.4-12.49,14.42-4.97,4.02-10.65,7.15-17.02,9.39s-13.19,3.35-20.45,3.35c-8.05,0-15.62-1.51-22.72-4.53-7.1-3.02-13.27-7.15-18.53-12.41-5.25-5.25-9.42-11.4-12.49-18.44-3.08-7.04-4.61-14.53-4.61-22.47Z"
                    />
                </g>
                <g>
                    <path
                        className="cls-4"
                        d="M112,68.17c0,3.84-3.14,6.42-6.99,5.73l-12.14-2.18v43.47c0,3.84-3.14,6.69-6.99,6.32-3.84-.37-6.99-3.82-6.99-7.66v-44.64l-7.33-1.32v-13.97l7.33,1.32v-10.35l13.97,2.51v10.35l12.14,2.18c3.84.69,6.99,4.4,6.99,8.24ZM37.58,55.66c0,3.84,3.14,7.55,6.99,8.24l6.75,1.21v-13.97l-6.75-1.21c-3.84-.69-6.99,1.89-6.99,5.73Z"
                    />
                    <path
                        className="cls-4"
                        d="M109.65,91.24c0,3.84-3.14,6.42-6.99,5.73l-6.72-1.21v-13.97l6.72,1.21c3.84.69,6.99,4.4,6.99,8.24ZM68.33,33.05c0-3.84-3.14-7.29-6.99-7.66-3.84-.37-6.99,2.47-6.99,6.32v43.47l-12.14-2.18c-3.84-.69-6.99,1.89-6.99,5.73s3.14,7.55,6.99,8.24l12.14,2.18v10.35l13.97,2.51v-10.35l7.33,1.32v-13.97l-7.33-1.32v-44.64Z"
                    />
                </g>
                <path
                    className="cls-4"
                    d="M73.61,145.26c-19.18,0-37.21-7.47-50.78-21.03C9.28,110.66,1.81,92.63,1.81,73.45s7.47-37.21,21.03-50.77h0C36.4,9.11,54.43,1.64,73.61,1.64c19.18,0,37.21,7.47,50.77,21.03s21.03,31.59,21.03,50.77-7.47,37.21-21.03,50.78-31.59,21.03-50.77,21.03ZM73.61,12.64c-16.24,0-31.51,6.33-43,17.81h0c-11.48,11.49-17.81,26.75-17.81,43s6.33,31.51,17.81,43c11.48,11.48,26.75,17.81,43,17.81s31.51-6.33,43-17.81c23.71-23.71,23.71-62.29,0-86-11.48-11.48-26.75-17.81-43-17.81Z"
                />
            </Box>
        );
    }
    return <Box component="img" src={mini ? thatchLogoMini : thatchLogo} alt="Thatch logo" sx={sx} />;
}

ThatchLogo.propTypes = {
    mini: PropTypes.bool,
    svg: PropTypes.bool,
    sx: PropTypes.any,
};
