import { Button, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import AccountMenu from '../../../components/AccountMenu/AccountMenu';
import BusinessLogo from '../../../components/BusinessLogo';
import { OrderStatuses } from '../../../constants/OrderStatuses';
import { PaymentStatuses } from '../../../constants/PaymentStatuses';
import CalendarCheckIcon from '../../../icons/CalendarCheckIcon';
import EyeIcon from '../../../icons/EyeIcon';
import FileXIcon from '../../../icons/FileXIcon';
import ProhibitIcon from '../../../icons/ProhibitIcon';
import ReceiptIcon from '../../../icons/ReceiptIcon';
import SealCheckIcon from '../../../icons/SealCheckIcon';
import ThumbsUpIcon from '../../../icons/ThumbsUpIcon';
import TruckIcon from '../../../icons/TruckIcon';
import WarningIcon from '../../../icons/WarningIcon';
import { Midnight } from '../../../theme.js';
import ArrowCircleLeftIcon from '../../../icons/ArrowCircleLeftIcon.jsx';
import { useNavigate } from 'react-router-dom';

export default function OrderDetailsHeader(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }
        return format(new Date(dateString), 'M/d/yy');
    };
    const lastPayment = [...(props?.order?.payments || [])].pop();

    const chipColor = {
        [OrderStatuses.APPROVED]: 'approved',
        [OrderStatuses.CANCELLED]: 'cancelled',
        [OrderStatuses.DECLINED]: 'destructive',
        [OrderStatuses.IN_REVIEW]: 'review',
        [OrderStatuses.SHIPPED]: 'shipped',
        [PaymentStatuses.COMPLETE]: 'complete',
        [PaymentStatuses.FAILED]: 'destructive',
    }[
        lastPayment?.status === PaymentStatuses.COMPLETE || lastPayment?.status === PaymentStatuses.FAILED
            ? lastPayment?.status
            : props?.order?.status
    ];
    const ChipIcon = {
        [OrderStatuses.APPROVED]: ThumbsUpIcon,
        [OrderStatuses.CANCELLED]: FileXIcon,
        [OrderStatuses.DECLINED]: ProhibitIcon,
        [OrderStatuses.IN_REVIEW]: EyeIcon,
        [OrderStatuses.SHIPPED]: TruckIcon,
        [PaymentStatuses.COMPLETE]: SealCheckIcon,
        [PaymentStatuses.FAILED]: WarningIcon,
    }[
        lastPayment?.status === PaymentStatuses.COMPLETE || lastPayment?.status === PaymentStatuses.FAILED
            ? lastPayment?.status
            : props?.order?.status
    ];

    const backToOrders = () => {
        navigate('/retail/orders');
    };

    return (
        <Stack sx={{ alignItems: 'left' }}>
            <Stack
                direction={{ xs: 'column-reverse', sm: 'row' }}
                sx={{
                    justifyContent: 'space-between',
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    width: '100%',
                }}>
                <Box sx={{ height: 55, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <Button onClick={backToOrders} sx={{ color: Midnight, padding: 0 }}>
                        <Stack gap={1.5} direction="row" sx={{ alignItems: 'center' }}>
                            <ArrowCircleLeftIcon sx={{ width: 24, height: 24 }} />
                            <Typography
                                noWrap
                                variant="textMd"
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {t('OrderDetailsHeader.back')}
                            </Typography>
                        </Stack>
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: { xs: '100%', sm: 'auto' } }}>
                    <Box>
                        <AccountMenu />
                    </Box>
                </Box>
            </Stack>

            <Stack gap={3} sx={{ flexDirection: 'row', flexWrap: { xs: 'wrap-reverse', md: 'nowrap' } }}>
                <Stack direction="row" gap={3}>
                    <Box sx={{ display: { xs: 'none', sm: 'initial' } }}>
                        <BusinessLogo
                            business={props?.business}
                            sx={{ height: 80, width: 80 }}
                            CardMediaProps={{
                                sx: {
                                    borderRadius: 4,
                                    boxShadow: '1px 1px 30px 0px rgba(0, 0, 0, 0.15)',
                                    objectFit: 'cover',
                                },
                            }}
                        />
                    </Box>
                    <Box>
                        <Typography
                            variant="displayMd"
                            sx={{
                                lineHeight: 'normal',
                            }}>
                            {t('OrderDetailsHeader.title')}
                        </Typography>
                        <Stack direction="row" alignItems="center" gap={'8px 16px'} flexWrap="wrap">
                            <Chip
                                icon={
                                    ChipIcon ? (
                                        <ChipIcon className="MuiIcon-root" sx={{ width: '16px', height: '16px' }} />
                                    ) : undefined
                                }
                                variant="orderStatus"
                                color={chipColor}
                                label={
                                    lastPayment?.status === PaymentStatuses.COMPLETE
                                        ? lastPayment?.status
                                        : props?.order?.status
                                }
                            />
                            <Stack direction="row" alignItems="center" gap={1}>
                                <ReceiptIcon sx={{ color: 'common.LightGray', width: '24px', height: '24px' }} />
                                <Typography
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        color: 'common.LightGray',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                    }}>
                                    {t('OrderDetailsHeader.orderNumberLabel')}
                                    {props?.order?.id}
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <CalendarCheckIcon sx={{ color: 'common.LightGray', width: '24px', height: '24px' }} />
                                <Typography
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        color: 'common.LightGray',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                    }}>
                                    {t('OrderDetailsHeader.placedOnLabel')} {formatDate(props?.order?.createdAt)}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    );
}

OrderDetailsHeader.propTypes = {
    business: PropTypes.any.isRequired,
    order: PropTypes.any.isRequired,
};
