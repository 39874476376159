import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import EditCardWrapper from '../../../../../../components/EditCardWrapper/EditCardWrapper.jsx';
import { Chip, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ProductIcon from '../../../../../../components/ProductIcon.jsx';
import EditProductVariantSelect from './EditProductVariantSelect/EditProductVariantSelect.jsx';
import _ from 'lodash';
import { EditProductVariantOptions } from './EditProductVariantOptions/EditProductVariantOptions.jsx';
import Pricing from '../../../GeneralProductInformation/Pricing.jsx';
import Media from '../../../BrandMedia/Media.jsx';
import Editor from '../../../../../../components/Editor/Editor.jsx';
import ProductIDs from '../../../GeneralProductInformation/ProductIDs.jsx';
import { usePrevious } from '../../../../../../helpers/customHooks.js';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ProductVariantEditPage({ product, onChange, onValidityChange, variantFieldsValidity }) {
    const [selectedVariant, setSelectedVariant] = useState();
    const [hideInactive, setHideInactive] = useState(true);
    const { t } = useTranslation();

    function handleVariantSelectionChange(selected) {
        if (!_.isEmpty(selected)) {
            setSelectedVariant({ ...selected });
        }
    }

    const prevSelectedVariant = usePrevious(selectedVariant);
    useEffect(() => {
        if (!_.isEqual(prevSelectedVariant, selectedVariant)) {
            const productsVariantsClone = _.cloneDeep(product.productVariants);
            const found = _.findIndex(productsVariantsClone, { id: selectedVariant?.id });
            productsVariantsClone[found] = selectedVariant;
            onChange(productsVariantsClone);
        }
    }, [selectedVariant]);

    useEffect(() => {
        const filter = { active: hideInactive };
        if (hideInactive) {
            filter.isDefault = true;
        }
        const selected = _.find(product.productVariants, filter);
        setSelectedVariant(selected);
    }, [hideInactive]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        const selectedClone = _.cloneDeep(selectedVariant);

        if (!_.isEmpty(selectedVariant)) {
            selectedClone[name] = value;
            setSelectedVariant(selectedClone);
        }
    };

    function handleOptionChange(variants) {
        onChange(variants);
    }

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    function handleValidity(e) {
        const v = e?.target?.value;
        const selectedValidity = _.find(variantFieldsValidity, { id: selectedVariant?.id });
        if (selectedValidity) {
            const newValidity = { ...selectedValidity };
            onValidityChange(_.set(newValidity, e?.target?.name, _.isEmpty(v)));
        } else {
            const newValidity = { id: selectedVariant?.id };
            onValidityChange(_.set(newValidity, e?.target?.name, _.isEmpty(v)));
        }
    }

    const selectedValidity = _.find(variantFieldsValidity, { id: selectedVariant?.id });
    const links = selectedVariant?.imageLinks || [];
    const description = selectedVariant?.description || '';

    function handleShow() {
        setHideInactive(!hideInactive);
    }

    const showText = hideInactive ? t('BrandProductTable.showArchived') : t('BrandProductTable.hideArchived');
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <EditCardWrapper title={'Product'}>
                    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', height: '50px', width: '100%' }}>
                            <ProductIcon
                                sx={{
                                    marginRight: '15px',
                                    flexGrow: '0',
                                    alignContent: 'center',
                                    justifyContent: 'left',
                                }}
                                src={product.imageLinks?.[0] || ''}
                            />
                            <Box sx={{ flexGrow: '1' }}>
                                <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    {product.title}
                                </Typography>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography
                                        align="center"
                                        sx={{
                                            flexGrow: '1',
                                            fontSize: '10px',
                                            display: 'flex',
                                            marginRight: '4px',
                                            alignItems: 'center',
                                        }}>
                                        {product.productVariants.length} Variants
                                    </Typography>
                                    {product.active ? <Chip size="small" label="active" /> : ''}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </EditCardWrapper>
                <EditCardWrapper
                    actionText={showText}
                    action={handleShow}
                    title={'Variants'}
                    sx={{ padding: 0, marginBottom: 0 }}>
                    <EditProductVariantSelect
                        selected={selectedVariant}
                        hideInactive={hideInactive}
                        options={product?.productVariants}
                        onChange={handleVariantSelectionChange}
                    />
                </EditCardWrapper>
            </Grid>

            <Grid item xs={12} sm={9}>
                <EditCardWrapper title={'Options'}>
                    <Grid container spacing={2}>
                        <EditProductVariantOptions
                            product={product}
                            selected={selectedVariant}
                            onChange={handleOptionChange}
                        />
                    </Grid>
                </EditCardWrapper>
                <EditCardWrapper title={'Variant Pricing'}>
                    <Pricing
                        product={selectedVariant}
                        onChange={handleChange}
                        handleValidity={handleValidity}
                        fieldsValidity={selectedValidity}
                    />
                </EditCardWrapper>
                <EditCardWrapper title={'Variant Media'}>
                    <Media
                        images={links}
                        handleImagesChange={(v) => {
                            const e = {
                                target: {
                                    value: v,
                                    name: 'imageLinks',
                                },
                            };
                            handleChange(e);
                        }}
                    />
                </EditCardWrapper>
                <EditCardWrapper title={'Variant Info'}>
                    <Editor
                        value={description}
                        placeholder="Description"
                        onChange={(v) => {
                            const e = {
                                target: {
                                    value: v,
                                    name: 'description',
                                },
                            };
                            handleChange(e);
                        }}
                    />
                </EditCardWrapper>
                <EditCardWrapper title={'Variant IDs'}>
                    <ProductIDs
                        product={selectedVariant}
                        onChange={handleChange}
                        handleValidity={handleValidity}
                        fieldsValidity={selectedValidity}
                    />
                </EditCardWrapper>
            </Grid>
        </Grid>
    );
}

export default ProductVariantEditPage;

ProductVariantEditPage.propTypes = {
    onChange: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    variantFieldsValidity: PropTypes.array,
};
