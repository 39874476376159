import React, { createContext, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as api from '../../api.js';

const SettingsContext = createContext({});
export const useSettingsContext = () => useContext(SettingsContext);
export const SettingsProvider = (props) => {
    const [initialUser, setInitialUser] = useState(props.initialUser);
    const [initialBusiness, setInitialBusiness] = useState(props.initialBusiness);

    const [user, setUser] = useState(props.initialUser);
    const [business, setBusiness] = useState(props.initialBusiness);
    const updateUser = (updatedUser) => {
        setUser(updatedUser);
    };

    const updateBusiness = (updatedBusiness) => {
        setBusiness(updatedBusiness);
    };

    const hasUserChanged = () => {
        return JSON.stringify(initialUser) !== JSON.stringify(user);
    };

    const hasBusinessChanged = () => {
        return JSON.stringify(initialBusiness) !== JSON.stringify(business);
    };

    const resetChanges = (type) => {
        switch (type) {
            case 'User':
                return updateUser(initialUser);
            case 'Business':
                return updateBusiness(initialBusiness);
        }
    };
    const saveChanges = async (type) => {
        const businessBody = {
            termsAgreement: business.termsAgreement,
            id: business.id,
            businessId: business.businessId,
            name: business.name,
            established: business.established,
            aboutUs: business.aboutUs,
            imageLinks: business.imageLinks,
            type: business.type,
            addresses: business.addresses,
            createdAt: business.createdAt,
            updatedAt: business.updatedAt,
            coverPhoto: business.coverPhoto,
            minOrderValue: business.minOrderValue,
        };
        let response;
        switch (type) {
            case 'User':
                try {
                    response = await api.updateUser(user, {});
                    setInitialUser(user);
                    return response;
                } catch (error) {
                    return error;
                }
            case 'Business':
                try {
                    response = await api.updateBusiness(business.id, businessBody, {});
                    setInitialBusiness(business);
                    return response;
                } catch (error) {
                    return error;
                }
            default:
                throw new Error('Invalid type provided');
        }
    };

    const contextValue = useMemo(
        () => ({
            user,
            updateUser,
            business,
            updateBusiness,
            hasUserChanged,
            hasBusinessChanged,
            saveChanges,
            resetChanges,
        }),
        [user, business]
    );

    return <SettingsContext.Provider value={contextValue}>{props.children}</SettingsContext.Provider>;
};

SettingsProvider.propTypes = {
    children: PropTypes.node,
    initialUser: PropTypes.any,
    initialBusiness: PropTypes.any,
};
