import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    AccountMenu: {
                        profileLabel: 'Profile',
                        allOrdersLabel: 'All Orders',
                        settingsLabel: 'Settings',
                        logOutLabel: 'Log Out',
                    },
                    AddressSelect: {
                        addLabel: 'Add New Address',
                    },
                    BusinessSetup: {
                        title: 'Company Info',
                        description: 'Tell us about your organization so we can help you connect and grow your company',
                        businessNameLabel: 'Business Name',
                        businessTypeLabel: 'Business Type',
                        businessAddressLabel: 'Business Address',
                        cityLabel: 'City',
                        cityPlaceholder: 'Enter City',
                        state: 'State',
                        statePlaceholder: 'Enter State',
                        zipLabel: 'Zip',
                        zipPlaceholder: 'Enter Zip',
                        businessEmailLabel: 'Business Email',
                        phoneLabel: 'Phone',
                        termsOfUseText: 'By signing up, I agree with the Terms of Use & Privacy Policy.',
                        continueButtonText: 'Continue',
                    },
                    BusinessSetupSuccess: {
                        welcomeMessage: 'Welcome to Thatch!',
                        congratulationsMessage: 'Congratulations, your account has been successfully created.',
                        enjoyMessage:
                            'Enjoy a seamless partnership between retailers and brands and start building community and connection.',
                        getStartedButton: "Let's Get Started",
                    },
                    BusinessTypeSelect: {
                        brandOptionLabel: 'Brand / Vendor',
                        retailerOptionLabel: 'Retailer',
                    },
                    BusinessForm: {
                        organizationNameInputLabel: 'Business Name',
                        organizationTypeInputLabel: 'Business Type',
                        addressLine1: 'Street',
                        city: 'City',
                        state: 'State',
                        zip: 'Zip',
                        phone: 'Phone',
                        agreement: 'By signing up, I agree with the ',
                        termsOfUse: 'Terms of Use',
                        privacyPolicy: 'Privacy Policy',
                        submitButtonLabel: 'Continue',
                        email: 'Business Email',
                    },
                    CancelOrderDialog: {
                        title: 'Cancel Order',
                        message:
                            'Are you sure you want to cancel order #{{orderId}}? This will permanently cancel your order.',
                        cancel: 'Not Now',
                        confirm: 'Cancel Order',
                    },
                    EditShippingAddressDialog: {
                        title: 'Edit Shipping Address',
                        cancelButton: 'Cancel',
                        submitButton: 'Save Changes',
                        selectNewAddressLabel: 'Select New Address',
                        addressLabelLabel: 'Address label',
                        addressLabel: 'Address',
                        cityLabel: 'City',
                        stateLabel: 'State',
                        zipLabel: 'Zip',
                        emailLabel: 'Email',
                        phoneLabel: 'Phone',
                        secondaryPhoneLabel: 'Secondary phone',
                    },
                    RemoveOrderItemDialog: {
                        title: 'Remove Product',
                        description: 'Are you sure you want to remove this product from the order?',
                        cancelBtnLabel: 'Cancel',
                        confirmBtnLabel: 'Remove',
                    },
                    /** @deprecated */
                    Cart: {
                        title: 'Cart',
                        searchMyCartLabel: 'Search my cart',
                        emptyLabel: 'No items in cart',
                        noMatchingResultsLabel: 'No results match your search criteria',
                        mpnLabel: 'MPN:',
                        skuLabel: 'SKU:',
                        removeProductDialog: {
                            title: 'Are you sure you want to remove this product?',
                            buttonLabel: 'Remove',
                        },
                    },
                    CartCheckout: {
                        shippingAddressLabel: 'Shipping Address',
                        editAddressLabel: 'Edit',
                        sendOrderLabel: 'Send Order',
                        clearCartLabel: 'Clear Cart',
                        emptyLabel: 'No items in cart',
                        chooseAddressLabel: 'Choose Address',
                        itemsLabel: 'Items',
                    },
                    CartSummaryHeader: {
                        title: 'Shopping Cart',
                    },
                    ClearCartDialog: {
                        title: 'Clear Cart',
                        details: 'Are you sure you want to clear the cart? All items will be removed.',
                        cancelLabel: 'Cancel',
                        clearLabel: 'Clear',
                    },
                    CartHeader: {
                        title: 'Shopping Cart',
                    },
                    EditLineItemsDialog: {
                        title: 'Manage Order Details',
                        shippingLabel: 'Shipping',
                        shippingSubtitle: 'Apply shipping cost to order',
                        discountLabel: 'Discount',
                        discountSubtitle: 'Apply master discount to entire order',
                        termsLabel: 'Terms',
                        termsSubtitle: 'Apply terms to the order',
                        buttons: {
                            primaryLabel: 'Apply',
                            secondaryLabel: 'Cancel',
                            resetLabel: 'Reset',
                        },
                    },
                    Integrations: {
                        titleLabel: 'Integrations',
                        promptLabel:
                            'Connect Thatch to your external software for inventory, product importing/exporting, and more.',
                        shopifyPrompt: 'Connect Shopify to allow inventory pushes.',
                        connectedStoresLabel: 'Connected Stores',
                        connectButton: 'Connect',
                        cancelButton: 'Cancel',
                        disconnectButton: 'Disconnect',
                        noShopsLabel: 'No linked shops',
                        shopNameLabel: 'Shop Name',
                        connectShopTitleLabel: 'Connect Shop',
                        enterNamePlaceholder: 'Enter Name Placeholder',
                        warningLabel: 'Warning',
                        warningDescription:
                            'By disconnecting from your Shopify store, your products from Shopify will remain, but they will no longer stay in sync with the corresponding products in Shopify.',
                        connectedDialogTitle: 'Connected',
                        connectedDialogDescription: 'Shopify is now connected to {{shopName}}.',
                        connectedDialogClose: 'Close',
                    },
                    OrderItemCard: {
                        mpnLabel: 'MPN:',
                        upcLabel: 'UPC:',
                        skuLabel: 'SKU:',
                        qtyLabel: 'QTY:',
                    },
                    OrderSummaryCard: {
                        title: 'Order Summary',
                        totalQuantityLabel: 'Total Qty',
                        shippingLabel: 'Shipping',
                        subtotalLabel: 'Subtotal',
                        termsLabel: 'Terms',
                        orderTotalLabel: 'Order Total',
                        statusLabel: 'Status:',
                        addShippingLabel: 'Add Shipping',
                        addDiscountLabel: 'Add Discount',
                        discountLabel: 'Discount',
                        buttons: {
                            manageDetailsLabel: 'Manage Order Details',
                            declineOrderLabel: 'Decline Order',
                            sendInvoiceLabel: 'Send Invoice',
                            approveSendInvoiceLabel: 'Approve / Send Invoice',
                            cancelOrderLabel: 'Cancel Order',
                            viewAllOrdersLabel: 'View all orders',
                            clearCart: 'Clear cart',
                            continue: 'Continue',
                            sendOrder: 'Send order',
                            viewInvoiceLabel: 'View Invoice',
                            payNowLabel: 'Pay Now',
                            takePaymentLabel: 'Take Payment',
                            markAsShippedLabel: 'Mark as Shipped',
                        },
                        alert: {
                            payoutsNotEnabled: 'Vendor is not able to accept payments at this time.',
                            completeStripeToCompletePayment: 'Complete Stripe onboarding to receive payments',
                        },
                        missingBusiness: 'There was an error displaying your order. Please reload.',
                    },
                    OrderSummaryDetails: {
                        totalQuantityLabel: 'Total Quantity',
                        subtotalLabel: 'Subtotal',
                        shippingLabel: 'Shipping',
                        discountLabel: 'Discount',
                        termsLabel: 'Terms',
                        orderTotalLabel: 'Order Total',
                    },
                    OrderStatusSelect: {
                        emptyLabel: 'All Statuses',
                    },
                    BrandSelect: {
                        emptyLabel: 'All Brands',
                    },
                    OrderSortSelect: {
                        newestFirst: 'Newest First',
                        oldestFirst: 'Oldest First',
                        totalMost: 'Order Total ∧',
                        totalLeast: 'Order Total ∨',
                    },
                    ProductDetails: {
                        mpnLabel: 'MPN:',
                        skuLabel: 'SKU:',
                        descriptionLabel: 'Product Description',
                        wholesaleLabel: 'Wholesale',
                        msrpLabel: 'MSRP',
                        quantityLabel: 'Quantity',
                        addToCartLabel: 'Add to Cart',
                        specsLabel: 'Specs',
                        addedToCartLabel: 'Added to cart!',
                        cartBusinessIdMismatch:
                            'You currently have products from another business in your cart, complete existing order or clear cart to order this product.',
                    },
                    ProductPicker: {
                        addButtonLabel: 'Add',
                        removeButtonLabel: 'Remove',
                        doneButtonLabel: 'Done',
                        mpnLabel: 'MPN',
                        productNameLabel: 'Product Name',
                        wholesaleLabel: 'Wholesale',
                    },
                    BrandOrders: {
                        newOrderButtonLabel: 'New Order',
                    },
                    BrandOrderDetails: {
                        paidLabel: 'PAID',
                        paymentFailedLabel: 'Payment FAILED',
                        noteLabel: 'Note',
                    },
                    RetailOrderDetails: {
                        title: 'Order #',
                        emptyLabel: 'No items',
                        detailsLabel: 'Details',
                        dateLabel: 'Order Submitted:',
                        itemsLabel: 'Items',
                        paidLabel: 'PAID',
                        paymentFailedLabel: 'Payment FAILED',
                        quantityLabel: 'Qty',
                        skuLabel: 'SKU',
                        mpnLabel: 'MPN',
                        shipToLabel: 'Ship to',
                        statusLabel: 'Status:',
                        trackingLabel: 'Tracking:',
                        commentsLabel: 'Comments',
                        SuccessDialog: {
                            title: 'Success!',
                            orderLabel: 'Order #',
                            description: 'Thank you for your payment!',
                            buttonLabel: 'Ok',
                        },
                        Alert: {
                            orderCreated: 'Success!',
                            orderError: 'There was a problem processing your order. Please try again.',
                        },
                    },
                    OrderDetailsHeader: {
                        back: 'Back To Orders',
                        title: 'Order Details',
                        orderNumberLabel: 'Order #',
                        placedOnLabel: 'Placed on',
                    },
                    RetailOrderListItem: {
                        shipToLabel: 'Ship to',
                        totalLabel: 'Total',
                        orderNumberLabel: 'Order #',
                        viewDetailsLabel: 'View Order Details',
                        invoiceLabel: 'Invoice',
                        statusLabel: 'Status',
                        dateCreated: 'Order Date',
                        messageLabel: 'Message',
                        trackLabel: 'Track',
                    },
                    RetailOrders: {
                        title: 'All Orders',
                        searchPlaceholder: 'Search all orders',
                        totalOrdersLabel: 'Total Orders',
                        statusLabel: 'Status',
                    },
                    Settings: {
                        backToHomeButton: 'Back to Home',
                        manageAddressesLabel: 'Manage Addresses',
                        companyProfileLabel: 'Company Profile',
                        inviteLabel: 'Invite a {{businessType}}',
                        integrationsLabel: 'Integrations',
                        dealerLabel: 'Dealer',
                        brandLabel: 'Brand',
                        paymentLabel: 'Payment',
                    },
                    ConnectedBrandProducts: {
                        productsLabel_one: '{{count}} product',
                        productsLabel_other: '{{count}} products',
                        emptyProductsLabel: 'No products',
                        searchPlaceholder: 'Search my products',
                        msrpLabel: 'MSRP',
                        wholesaleLabel: 'Wholesale',
                        variantsLabel: 'Variants',
                        viewItemLabel: 'View item',
                        establishedLabel: 'Established:',
                        headquartersLabel: 'Headquarters:',
                        categoryLabel: 'Category',
                        priceLabel: 'Price',
                        aboutBrandLabel: 'About The Brand',
                        buttons: {
                            messagesLabel: 'Messages',
                            ordersLabel: 'Orders',
                            reportsLabel: 'Reports',
                            allFiltersLabel: 'All Filters',
                        },
                    },
                    Connections: {
                        title: 'Connections',
                        breadcrumbLabel: 'My Brands',
                        Connection: { locationLabel: 'Location' },
                        emptyStateLabel: 'No connections were found.',
                        pleaseClickLabel: 'Please click',
                        hereLabel: 'here',
                        toConnectLabel: 'to add new connections.',
                    },
                    UserProfile: {
                        title: 'My Profile',
                        firstNameInputLabel: 'First Name',
                        firstNameInputPlaceholder: 'Enter First Name',
                        titleInputLabel: 'Title',
                        titleInputPlaceholder: 'Enter Title',
                        lastNameInputLabel: 'Last Name',
                        lastNameInputPlaceholder: 'Enter Last Name',
                        emailInputLabel: 'Email',
                        emailInputPlaceholder: 'Enter Email',
                        phoneInputLabel: 'Phone',
                        secondaryPhoneInputLabel: 'Secondary Phone',
                        saveButton: 'Save Changes',
                        successMessage: 'Info Updated!',
                    },
                    Invite: {
                        emailInputLabel: 'Email',
                        title: 'Invite a {{type}}',
                        prompt: 'Seamlessly partner with your favorite {{type}} by inviting them to join Thatch!',
                        inviteSent: 'Invite Sent',
                        sendInviteLabel: 'Send an Invite',
                        sendInviteButtonLabel: 'Send Invite',
                        emailPlaceholder: 'Enter Email',
                        addButtonLabel: 'Add another',
                    },
                    InviteAConnectionDialog: { title: 'Invite a {{type}}', brandLabel: 'Brand', dealerLabel: 'Dealer' },
                    CompanyProfile: {
                        bioInputLabel: 'Bio',
                        businessNameInputLabel: 'Business Name',
                        cityInputLabel: 'City',
                        stateInputLabel: 'State',
                        zipInputLabel: 'Zip',
                        businessTypeInputLabel: 'Business Type',
                        establishedInputLabel: 'Year Established',
                        bioPlaceholder: 'Tell us about your company (max 1000 characters)',
                        addressInputLabel: 'Address',
                        organizationEmailInputLabel: 'Categories Email',
                        phoneInputLabel: 'Phone',
                        secondaryPhoneInputLabel: 'Secondary Phone',
                        coverPhotoInputLabel: 'Add Cover Photo',
                        locationsInputLabel: 'Locations',
                        minOrderInputLabel: 'Order Minimum',
                        minOrderInputPlaceholder: 'Enter Value',
                        okButton: 'Ok',
                        characterLimitExceeded: 'Character limit succeeded (1000)',
                    },
                    MinimumOrder: {
                        title: 'Min. Order:',
                    },
                    CoverPhoto: {
                        promptLabel: 'Choose a file or drag and drop images',
                        recommendationLabel: 'Recommended size 950x265px',
                    },
                    AddAddressInput: {
                        titleLabel: 'Add New Address',
                        editTitleLabel: 'Edit Address',
                        addressInputLabel: 'Address',
                        addressInputPlaceholder: 'Enter Address',
                        cityInputLabel: 'City',
                        addressLabelInputLabel: 'Address Label',
                        addressLabelInputPlaceholder: 'Enter Label',
                        emailInputPlaceholder: 'Enter Email',
                        cityInputPlaceholder: 'Enter City',
                        phoneInputLabel: 'Phone',
                        phoneInputPlaceholder: 'Enter Number',
                        secondaryPhoneInputLabel: 'Secondary Phone',
                        secondaryPhoneInputPlaceholder: 'Enter Number',
                        emailInputLabel: 'Email',
                        streetInputLabel: 'About Us',
                        buildingTypeInputLabel: 'Address',
                        stateInputLabel: 'State',
                        zipCodeInputLabel: 'Zip',
                        zipCodeInputPlaceholder: 'Enter Zip',
                        countryInputLabel: 'Locations',
                        checkBoxPrompt: 'Mark as Primary Address',
                        submitNewAddressPrompt: 'Add Address',
                        submitExistingAddressPrompt: 'Save Address',
                        primaryLabel: 'Primary',
                    },
                    AddUserInput: {
                        addUserTitle: 'Add a user',
                        firstNameInputLabel: 'First Name',
                        firstNameInputPlaceholder: 'Enter First Name',
                        lastNameInputLabel: 'Last Name',
                        lastNameInputPlaceholder: 'Enter Last Name',
                        emailInputLabel: 'Email',
                        emailInputPlaceholder: 'Enter Email',
                        addNewUserButtonLabel: 'Add New User',
                    },
                    Payment: {
                        title: 'Payment Details',
                        prompt: 'Manage your payment details for your brand account.',
                        subTitle: 'Connect Payment Method',
                        stripePrompt: 'Connect to stripe to start receiving payments from your dealers.',
                        connectToStripeLabel: 'Connect to Stripe',
                        loginToStripeLabel: 'Login to Stripe',
                        revokeButtonLabel: 'Revoke Stripe Access',
                        refresh: 'Refresh stripe',
                        onboardingCompleteLabel: 'Setup complete',
                        payoutsEnabledLabel: 'Payouts enabled',
                        connectedBankAccountLabel: 'Bank account connected',
                    },
                    Brand: {
                        ProductForm: {
                            Pricing: {
                                msrpLabel: 'MSRP',
                                wholesaleLabel: 'Wholesale',
                                retailDefaultPriceLabel: 'Retail Default Price',
                                mapPricingLabel: 'MAP Pricing',
                            },
                        },
                    },
                    BrandClearOrderDialog: {
                        title: 'Clear Order?',
                        details: 'All products in order will be removed.',
                        cancelLabel: 'Cancel',
                        clearLabel: 'Clear',
                    },
                    BrandCreateOrder: {
                        title: 'New Order',
                        searchPlaceholderLabel: 'Search and add products',
                        shortSearchPlaceholderLabel: 'Add products',
                        totalQtyLabel: 'Total Qty',
                        subtotalValueLabel: 'Subtotal Value',
                        orderTotalValueLabel: 'Order Total Value',
                        emptyResultsLabel: 'Add Products by searching above',
                        clearLabel: 'Clear',
                        submitLabel: 'Submit',
                        productsTitleLabel: 'Product(s) added to pending order',
                    },
                    Edit: {
                        changeLabel: 'Change',
                        deleteLabel: 'Delete',
                    },
                    Error: {
                        apiDown: 'Looks like our API is down',
                        malformedRequest: 'Hey Developer, there is something wrong with your request',
                        notAuthorized: "You aren't authorized to see this",
                        notFound: "Hmmm.. we can't find the resource you've requested.",
                        somethingWrong:
                            'A network error occurred, please try again. If the problem persists please contact Thatch.',
                        somethingElseWrong: 'Oops an error occurred.',
                    },
                    Root: {
                        onboardingIncomplete: 'Complete stripe onboarding in order to receive payments',
                        paymentLinkLabel: 'Manage payment',
                        homeLabel: 'Home',
                    },
                    ThatchAppBar: {
                        cartLabel: 'Cart',
                        connectionsLabel: 'Connections',
                        productsLabel: 'Products',
                        ordersLabel: 'Orders',
                        userProfileLabel: 'User Profile',
                        settingsLabel: 'Settings',
                        signOutLabel: 'Sign Out',
                        myBrandsLabel: 'My Brands',
                        allOrdersLabel: 'All Orders',
                    },
                    BusinessAppBar: {
                        orders: 'Orders',
                        products: 'Products',
                    },
                    PriceSlider: {
                        goLabel: 'Go',
                    },
                    ProductCard: {
                        optionsLabel_one: '{{count}} option',
                        optionsLabel_other: '{{count}} options',
                        msrpLabel: 'MSRP',
                    },
                    ProductHeader: {
                        msrpLabel: 'MSRP',
                    },
                    BrandProductCard: {
                        mpnLabel: 'MPN',
                        skuLabel: 'SKU',
                        msrpLabel: 'MSRP',
                        upcLabel: 'UPC',
                        thirdPartyIdLabel: 'Third Party ID',
                        quantityLabel: 'Qty',
                        variantOptionsLabel: 'Variant Option(s)',
                        variantsLabel: 'Variants',
                        wholesaleLabel: 'Wholesale',
                    },
                    BrandProductEditButtons: {
                        createProductLabel: 'Create Product',
                        saveChangesLabel: 'Save Changes',
                    },
                    BrandProductTable: {
                        createProductBtn: 'Create Product',
                        showArchived: 'Show Archived',
                        hideArchived: 'Hide Archived',
                        syncWithShopify: 'Sync with Shopify',
                    },
                    SelectDealer: {
                        searchLabel: 'Search',
                        addDealerLabel: 'Add Dealer',
                        doneLabel: 'Done',
                        addressesLabel: 'Addresses',
                    },
                    Media: {
                        addMediaButtonLabel: 'Add Media',
                        defaultLabel: 'Default',
                        description: 'Images, Videos, PDFs, Pricing, etc.',
                        makeDefaultButtonLabel: 'Make Default',
                        removeButtonLabel: 'Remove',
                    },
                    OrderNote: {
                        inputLabel: 'Note',
                        placeholder: 'Add new comment',
                        emptyNoteLabel: '(empty)',
                        submitButtonLabel: 'Post',
                        clearButtonLabel: 'Clear',
                    },
                    SubmitOrderDialog: {
                        title: 'Submit Order',
                        orderLabel: 'Order',
                        inputLabel: 'Note',
                        button: {
                            addNoteLabel: 'Add Note',
                            continueLabel: 'Send without Note',
                        },
                    },
                    SideCart: {
                        subtotalLabel: 'Subtotal',
                        itemsLabel: 'items',
                        reviewOrderButtonLabel: 'Review Order',
                        poweredByLabel: 'Powered by',
                    },
                    PoweredByThatch: {
                        poweredByLabel: 'Powered by',
                    },
                    SignIn: {
                        signInLabel: 'Sign In',
                        signInPrompt: 'Welcome back! Please enter your details.',
                        emailLabel: 'Email',
                        passwordLabel: 'Password',
                        forgotPasswordButton: 'Forgot Password',
                        signUpButton: 'Sign Up',
                        haveAccountLabel: `Don't have an account?`,
                        welcomeLabel: 'Cloud-based\nwholesaling and\ncollaboration\nfor brands and\nretailers',
                    },
                    SignUp: {
                        createAccountLabel: 'Create Account',
                        signUpPrompt: 'Sign up to collaborate simple and seamlessly.',
                        userEmailLabel: 'User Email',
                        passwordLabel: 'Password',
                        confirmPasswordLabel: 'Confirm Password',
                        signInButton: 'Sign In',
                        haveAccountLabel: `Already have an account?`,
                        welcomeLabel: 'Fundamentally\nchanging how\nretail commerce\nhappens.',
                    },
                    ForgotPassword: {
                        forgotPasswordLabel: 'Forgot Password',
                        forgotPasswordPrompt: 'Enter your email for instructions on how to reset it.',
                        emailLabel: 'Email',
                        backToSignInButton: 'Back To Sign In',
                        resetPasswordLabel: 'Reset Password',
                        emailPlaceholder: 'Enter email address',
                    },
                    ConfirmPassword: {
                        verificationLabel: 'Verification',
                        verificationPrompt:
                            'Enter the code we sent to your email address and enter your new password below.',
                        codeLabel: 'Code',
                        enterCodePlaceholder: 'Enter code',
                        newPasswordLabel: 'New Password',
                        newPasswordPlaceholder: 'Create password',
                        confirmNewPassword: 'Confirm New Password',
                        confirmPasswordPlaceholder: 'Confirm password',
                        resendCodeButton: 'Resend Code',
                        resetPasswordButton: 'Reset Password',
                    },
                    CreateNewPassword: {
                        title: 'Create New Password',
                        newPasswordPrompt: 'Generate your own new password',
                        newPasswordLabel: 'New Password',
                        newPasswordPlaceholder: 'Create Password',
                        confirmNewPassword: 'Confirm New Password',
                        confirmPasswordPlaceholder: 'Confirm Password',
                        updatePasswordButton: 'Submit',
                        backToSignInButton: 'Back to Sign In',
                        errorTitle: 'Error',
                        errorMessage:
                            'An unknown error occurred when changing your password. To try again, please return to the sign in page and re enter your email and temporary password.',
                    },
                    ConfirmSignUp: {
                        verifyLabel: 'Verify Email',
                        verificationPrompt:
                            'Your code is on the way. To log in, enter the code we emailed to {{email}}. It may take a minute to arrive.',
                        confirmationCodeLabel: 'Confirmation Code',
                        enterCodePlaceholder: 'Enter confirmation code',
                        resendEmailButton: 'Resend Email',
                        confirmButton: 'Confirm',
                    },
                    OrderItems: {
                        showLessButton: 'Show Less',
                        moreButton: '+{{number}} more',
                        variantsLabel: '{{count}} Variant',
                        variantsLabel_other: '{{count}} Variants',
                    },
                    ManageAddresses: {
                        titleLabel: 'Manage Shipping Addresses',
                        subTitleLabel: 'Manage Addresses',
                        promptLabel: 'We ship products to the addresses associated with this store front.',
                        addAddressButtonLabel: 'Add Address',
                    },
                    EditMenu: {
                        deleteAction: 'Delete',
                        editAction: 'Edit',
                    },
                },
            },
        },
    });

export default i18n;
