import { redirect } from 'react-router-dom';
import { BusinessTypes } from '../../constants/BusinessTypes';
import * as businessProvider from '../../providers/businessProvider';
import { BRAND_HOME_URL } from '../routesUtil';

/**
 * Retail loader prevents brands from viewing retail pages
 * @param {import("react-router-dom").LoaderFunctionArgs} params
 */
export async function loader({ request }) {
    const business = await businessProvider.getBusiness({ signal: request.signal });
    if (business?.type === BusinessTypes.BRAND) {
        const { pathname } = new URL(request.url);
        if (pathname.includes('/retail/orders/')) {
            const path = pathname.replace('retail', 'brand');
            return redirect(path);
        }
        return redirect(BRAND_HOME_URL);
    }
    return null;
}
