import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Form, redirect, useFetcher } from 'react-router-dom';
import { useTheme, Snackbar, Alert, useMediaQuery } from '@mui/material';
import _ from 'lodash';
import LoadingButton from '../../components/LoadingButton/LoadingButton.jsx';
import thatchLogo from '../../assets/ThatchLogo--Tide.svg';
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth';
import FormInputLabel from '../../components/FormInputLabel/FormInputLabel.jsx';
import TextField from '@mui/material/TextField';
import PasswordTextField from '../../components/PasswordTextField/PasswordTextField.jsx';
import List from '@mui/material/List';
import { validatePasswords } from '../../helpers/HelperFunctions.jsx';
import { useTranslation } from 'react-i18next';
import EnvelopeOpenIcon from '../../icons/EnvelopeOpenIcon.jsx';

export async function action({ request }) {
    try {
        const formData = await request.formData();
        const intent = formData.get('intent');
        const username = formData.get('username');

        if (intent === 'submitCode') {
            const confirmationCode = formData.get('code');
            const newPassword = formData.get('password');
            await confirmResetPassword({ username, newPassword, confirmationCode });
            localStorage.removeItem('passwordResetEmail');
            return redirect('/');
        }
        if (intent === 'resendCode') {
            await resetPassword({ username });
            return { status: 'code resent' };
        }

        return null;
    } catch (error) {
        return { error };
    }
}

export const mapErrorMessage = (error) => {
    if (error.toString().includes('CodeMismatchException')) {
        return 'Invalid verification code. Please try again';
    } else if (error.toString().includes('LimitExceededException')) {
        return 'Attempt limit exceeded, please try after some time.';
    } else {
        return 'Failed to reset password. Please check your code and try again.';
    }
};

export default function ConfirmPasswordReset() {
    const theme = useTheme();
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currentIntent, setCurrentIntent] = useState('');
    const fetcher = useFetcher();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const passwordResetEmail = localStorage.getItem('passwordResetEmail');
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const [passwordErrors, setPasswordErrors] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (fetcher.data?.status === 'code resent') {
            setSnackbarOpen(true);
        }
        if (fetcher.state === 'idle') {
            setCurrentIntent('');
        }
    }, [fetcher.data, fetcher.state]);

    const submitButtonEnabled = () => {
        return !_.isEmpty(code) && !_.isEmpty(password) && !_.isEmpty(confirmPassword) && _.isEmpty(passwordErrors);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const resendCode = () => {
        setPassword('');
        setConfirmPassword('');
        setCode('');
        setCurrentIntent('resendCode');
        fetcher.submit(
            {
                intent: 'resendCode',
                username: passwordResetEmail,
            },
            { method: 'post' }
        );
    };

    const submitCode = () => {
        setCurrentIntent('submitCode');
        fetcher.submit(
            {
                intent: 'submitCode',
                username: passwordResetEmail,
                code,
                password,
            },
            { method: 'post' }
        );
    };

    useEffect(() => {
        setPasswordErrors(validatePasswords({ password, confirmPassword }));
    }, [password, confirmPassword]);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
                width: '100%',
            }}>
            <Form method="POST">
                <input hidden name="username" value={passwordResetEmail} readOnly />
                <Stack gap={10} sx={{ height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Stack
                        gap={2}
                        sx={{
                            alignItems: 'flex-start',
                            width: {
                                xs: '90%',
                                sm: '400px',
                            },
                            mt: 15,
                        }}>
                        <EnvelopeOpenIcon sx={{ color: 'common.Midnight', width: 48, height: 48 }} />
                        <Stack gap={2} sx={{ width: '100%', justifyContent: 'center' }}>
                            <Typography variant="displayMd">{t('ConfirmPassword.verificationLabel')}</Typography>
                            <Typography sx={{ color: theme.palette.common.LightGray }}>
                                {t('ConfirmPassword.verificationPrompt')}
                            </Typography>
                            <Stack gap={3}>
                                <FormInputLabel text={t('ConfirmPassword.codeLabel')}>
                                    <TextField
                                        fullWidth
                                        placeholder={t('ConfirmPassword.enterCodePlaceholder')}
                                        name="code"
                                        value={code}
                                        onChange={(event) => setCode(event.target.value)}
                                        autoComplete="one-time-code"
                                    />
                                </FormInputLabel>
                                <FormInputLabel text={t('ConfirmPassword.newPasswordLabel')}>
                                    <PasswordTextField
                                        fullWidth
                                        placeholder={t('ConfirmPassword.newPasswordPlaceholder')}
                                        name="password"
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        autoComplete="new-password"
                                    />
                                </FormInputLabel>
                                {passwordErrors.length > 0 ? (
                                    <List>
                                        {passwordErrors.map((error, index) => (
                                            <Typography sx={{ color: 'common.Teal' }} key={index}>
                                                {error}
                                            </Typography>
                                        ))}
                                    </List>
                                ) : null}
                                <FormInputLabel text={t('ConfirmPassword.confirmNewPassword')}>
                                    <PasswordTextField
                                        fullWidth
                                        placeholder={t('ConfirmPassword.confirmPasswordPlaceholder')}
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(event) => setConfirmPassword(event.target.value)}
                                        autoComplete="new-password"
                                    />
                                </FormInputLabel>
                                {fetcher.data?.error ? (
                                    <Typography
                                        sx={{
                                            color: theme.palette.common.Alert,
                                        }}>{`Error: ${mapErrorMessage(fetcher.data.error)}`}</Typography>
                                ) : null}
                                <Stack
                                    gap={1}
                                    direction="row"
                                    sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <LoadingButton
                                        onClick={resendCode}
                                        variant="outlined"
                                        color="secondary"
                                        size={isSmBreakpoint ? undefined : 'sm'}
                                        sx={{ flex: 1 }}
                                        isLoading={currentIntent === 'resendCode' && fetcher.state === 'submitting'}>
                                        {t('ConfirmPassword.resendCodeButton')}
                                    </LoadingButton>
                                    <LoadingButton
                                        disabled={!submitButtonEnabled()}
                                        isLoading={currentIntent === 'submitCode' && fetcher.state === 'submitting'}
                                        onClick={submitCode}
                                        variant="contained"
                                        color="primary"
                                        size={isSmBreakpoint ? undefined : 'sm'}
                                        sx={{ flex: 1 }}>
                                        {t('ConfirmPassword.resetPasswordButton')}
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box component="img" src={thatchLogo} alt="Logo" sx={{ height: '25.7px', width: '150px', mb: 5 }} />
                </Stack>
            </Form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    New verification code sent!
                </Alert>
            </Snackbar>
        </Box>
    );
}
