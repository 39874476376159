import React from 'react';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { White } from '../../../theme.js';
import Button from '@mui/material/Button';
import ArrowCircleLeftIcon from '../../../icons/ArrowCircleLeftIcon.jsx';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import BuildingsIcon from '../../../icons/BuildingsIcon.jsx';
import HandshakeIcon from '../../../icons/HandshakeIcon.jsx';
import { SettingsType } from '../../../constants/SettingsType.jsx';
import MapPinIcon from '../../../icons/MapPinIcon.jsx';
import ThatchLogoMiniBorderedIcon from '../../../icons/ThatchLogoMiniBorderedIcon.jsx';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import ListIcon from '../../../icons/ListIcon.jsx';
import { useTranslation } from 'react-i18next';
import TreeIcon from '../../../icons/TreeIcon.jsx';
import { BusinessTypes } from '../../../constants/BusinessTypes.jsx';
import SettingItem from '../../../routes/settings/SettingItem/SettingItem.jsx';
import ThatchDrawer from '../../ThatchDrawer/ThatchDrawer.jsx';
import CreditCardIcon from '../../../icons/CreditCardIcon.jsx';

export default function SettingsList(props) {
    const { businessType, handleSettingsSelect } = props;
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const [showDrawer, setShowDrawer] = React.useState(false);
    const drawerWidth = isSmBreakpoint ? '240px' : '85%';

    const navigateBack = () => {
        setShowDrawer(false);
        if (businessType === BusinessTypes.BRAND) {
            navigate('/brand/orders');
        } else {
            navigate('/retail/connections');
        }
    };

    const isSettingSelected = (settingType) => {
        const pathMap = {
            UserProfile: '/user-profile',
            Notifications: '/notifications',
            Payment: '/payment',
            CompanyProfile: '/company-profile',
            CompanyUsers: '/company-users',
            Invite: '/invite',
            ManageAddresses: '/manage-addresses',
            Integrations: '/integrations',
        };
        return location.pathname.includes(pathMap[settingType]);
    };

    const selectSetting = (type) => {
        setShowDrawer(false);
        handleSettingsSelect(type);
    };

    return (
        <Box>
            {!isSmBreakpoint && !showDrawer && (
                <IconButton onClick={() => setShowDrawer(true)} sx={{ position: 'absolute', top: 20, left: 16 }}>
                    <ListIcon sx={{ width: 32, height: 32 }} />
                </IconButton>
            )}
            <ThatchDrawer open={showDrawer} onClose={() => setShowDrawer(false)} drawerWidth={drawerWidth}>
                <Stack gap={3} sx={{ px: 1.5, py: 3 }}>
                    <Stack gap={2}>
                        <Box>
                            <ThatchLogoMiniBorderedIcon sx={{ width: 40, height: 40 }} />
                        </Box>
                        <Button
                            onClick={navigateBack}
                            sx={{
                                display: 'flex',
                                width: '100%',
                                padding: 0,
                                justifyContent: 'flex-start',
                                gap: 1,
                            }}>
                            <ArrowCircleLeftIcon sx={{ width: 32, height: 32, color: White }} />
                            <Typography variant="textSm" sx={{ color: White, fontWeight: 'bold' }}>
                                {t('Settings.backToHomeButton')}
                            </Typography>
                        </Button>
                    </Stack>
                    <List>
                        <Stack gap={1}>
                            <SettingItem
                                settingType={SettingsType.CompanyProfile}
                                icon={BuildingsIcon}
                                label={t('Settings.companyProfileLabel')}
                                isSelected={isSettingSelected(SettingsType.CompanyProfile)}
                                onClick={() => selectSetting(SettingsType.CompanyProfile)}
                            />
                            {businessType === BusinessTypes.BRAND && (
                                <SettingItem
                                    settingType={SettingsType.Payment}
                                    icon={CreditCardIcon}
                                    label={t('Settings.paymentLabel')}
                                    isSelected={isSettingSelected(SettingsType.Payment)}
                                    onClick={() => selectSetting(SettingsType.Payment)}
                                />
                            )}
                            <SettingItem
                                settingType={SettingsType.Invite}
                                icon={HandshakeIcon}
                                label={t('Settings.inviteLabel', {
                                    businessType:
                                        businessType === BusinessTypes.BRAND
                                            ? t('Settings.dealerLabel')
                                            : t('Settings.brandLabel'),
                                })}
                                isSelected={isSettingSelected(SettingsType.Invite)}
                                onClick={() => selectSetting(SettingsType.Invite)}
                            />
                            <SettingItem
                                settingType={SettingsType.ManageAddresses}
                                icon={MapPinIcon}
                                label={t('Settings.manageAddressesLabel')}
                                isSelected={isSettingSelected(SettingsType.ManageAddresses)}
                                onClick={() => selectSetting(SettingsType.ManageAddresses)}
                            />
                            {businessType === BusinessTypes.BRAND && (
                                <SettingItem
                                    settingType={SettingsType.Integrations}
                                    icon={TreeIcon}
                                    label={t('Settings.integrationsLabel')}
                                    isSelected={isSettingSelected(SettingsType.Integrations)}
                                    onClick={() => selectSetting(SettingsType.Integrations)}
                                />
                            )}
                        </Stack>
                    </List>
                </Stack>
            </ThatchDrawer>
        </Box>
    );
}

SettingsList.propTypes = {
    handleSettingsSelect: PropTypes.func,
    businessType: PropTypes.string,
};
