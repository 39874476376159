import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import ProductIcon from '../../../../../../../components/ProductIcon.jsx';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { usePrevious } from '../../../../../../../helpers/customHooks.js';

export default function EditProductVariantSelect(props) {
    const [selected, setSelected] = useState([]);

    function handleClick(item) {
        return () => {
            const optionsClone = _.cloneDeep(props.options);
            const i = _.findIndex(props.options, item);

            optionsClone.map((item) => {
                item.isSelected = false;
                return item;
            });
            optionsClone[i].isSelected = true;
            setSelected(optionsClone[i].isSelected);

            props.onChange(optionsClone[i]);
        };
    }

    const prevSelectedID = usePrevious(props.selected?.id);

    useEffect(() => {
        if (!_.isEqual(props.selected?.id, prevSelectedID)) {
            setSelected(props.selected);
        }
    }, [props.selected?.id]);

    const items = _.chain(props.options)
        .filter({ active: props.hideInactive })
        .orderBy({ isDefault: true }, 'desc')
        .value();

    const view = items.map((variant, i) => {
        const tabColor = variant.id === selected.id ? 'primary.main' : '';
        const bckColor = variant.id === selected.id ? 'common.lightGrey' : '';

        let selectedStyle;

        // set border radius correctly
        const index = i + 1;
        if (index === 1) {
            selectedStyle = '5px 0 0 0 ';
        }
        if (index > 2) {
            selectedStyle = '0 0 0 0 ';
        }
        if (index === items.length) {
            selectedStyle = '0 0 0 5px ';
        }
        return (
            <Box
                onClick={handleClick(variant)}
                sx={{
                    display: 'flex',
                    height: '50px',
                    cursor: 'pointer',
                    backgroundColor: bckColor,
                    paddingRight: '10px',
                }}
                key={variant.id}>
                <Box sx={{ backgroundColor: tabColor, borderRadius: selectedStyle, width: '18px' }}></Box>
                <ProductIcon sx={{ width: '40px', marginRight: '10px' }} src={variant.imageLinks[0]}></ProductIcon>
                <Typography sx={{ textAlign: 'center', marginTop: '13px', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    {variant.variantTags.map((attr) => attr.value).join(' / ')}
                </Typography>
            </Box>
        );
    });

    return view;
}

EditProductVariantSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            isSelected: PropTypes.bool,
            imageLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
            variantTags: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.string.isRequired,
                })
            ).isRequired,
        })
    ).isRequired,
    showActive: PropTypes.bool,
    hideInactive: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};
