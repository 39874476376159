import { json } from 'react-router';
import queryString from 'query-string';
import { del, get, handleFetchResponse, patch, post, put } from './providers/fetchUtil';
import * as config from './providers/config/config';
import sanitizeFileName from './helpers/sanitizeFileName';
import * as Sentry from '@sentry/react';

const { DEV_THATCH_API_URL } = config.ENVIRONMENT_VARIABLES_ENUMS;

/// ////////////////
// API
//

/**
 * Get User
 * `/user/whoAmI` - gets the authorized user
 * @param {Object} options
 * @param {*} options.signal - signal
 * @returns {Promise<Object>}
 */
export async function getUser(options) {
    const res = await get('/user/whoAmI', options);
    return handleFetchResponse(res);
}

/**
 * Get User
 * `/user/:id` - gets a user by the user's id
 * @param {string} id
 * @param {Object} options
 * @param {*} options.signal - signal
 * @returns
 */
export async function getUserById({ id, signal }) {
    const path = '/user/' + id;
    const res = await get(path, { signal });
    return handleFetchResponse(res);
}

/**
 * Get Users
 * `/user` - gets users
 * @param {number} limit
 * @param {number} offset
 * @param {string} sort
 * @param {string} startDate
 * @param {string} endDate
 * @param signal
 * @returns
 */
export async function getUsers({ limit, offset, sort, startDate, endDate, signal }) {
    const path = '/user';
    const queryParams = {
        limit,
        offset,
        sort,
        startDate,
        endDate,
    };

    const queryString = Object.entries(queryParams)
        .filter(([key, value]) => value !== null && value !== undefined)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

    const url = `${path}?${queryString}`;
    const res = await get(url, { signal });
    return handleFetchResponse(res);
}

/**
 * Update User
 * `/user/:id` - Updates specified user
 * This function makes an HTTP PUT request to the '/user/:id' endpoint to update business-related data.
 * @param {Object} user
 * @param {Object} options
 * @returns {Promise<Object>}
 */
export async function updateUser(user, options) {
    const path = '/user/' + user.id;
    const res = await put(path, user, options);
    return handleFetchResponse(res);
}

/**
 * Create User
 * `/user` - creates user with provided details
 * This function makes an HTTP POST request to the '/user' endpoint to create a new user
 * @param {Object} user
 * @param {Object} options
 * @returns {Promise<Object>}
 */
export async function createUser(user, options) {
    const path = '/user';
    const res = await post(path, user, options);
    return handleFetchResponse(res);
}
/**
 * Create Retailer
 * `/user` - creates retailer on the fly when checking out with provided details
 * This function makes an HTTP POST request to the '/createRetailer' endpoint to create a new retailer
 * @param {Object} payload
 * @param {Object} options
 * @returns {Promise<Object>}
 */
export async function createRetailer(payload, options) {
    const path = '/createRetailer';
    const res = await post(path, payload, options);
    return handleFetchResponse(res);
}
/**
 * Get Business Information
 * Retrieves business information for specified id
 * @param {number} id
 * @param {Object} [options]
 * @returns {Promise<Object>} A promise that resolves with the business information as a JSON object.
 */
export async function getBusiness(id, options = {}) {
    const res = await get(`/business/${id}`, options);
    return handleFetchResponse(res);
}

/**
 * Get a set of businesses
 *
 * @param {Object} options
 * @param {*} options.signal
 * @returns {Promise<Object>}
 */
export async function getDefaultBusiness(options = {}) {
    const { signal } = options;
    const res = await get('/business/default', { signal });
    return handleFetchResponse(res);
}

/**
 * TODO this is a temporary method to help prevent extra changes for now
 * Get the set of connected businesses
 *
 * @param {Object} options
 * @param {*} options.signal
 * @returns {Promise<Object>}
 */
export async function getBusinesses(options = {}) {
    const { signal } = options;
    const res = await get('/business/default', { signal });
    const { status } = res;
    if (status >= 400) {
        // Handle HTTP errors
        throw json({ status });
    }
    const jsonResult = await res.json();
    return jsonResult.connectedBusiness; // Parse and return the JSON response
}

/**
 * Fetch products
 * @param {*} options
 * @returns
 */
export async function getProducts(options = {}) {
    try {
        const { query, signal } = options;
        let url = '/product';
        if (query) {
            url += '?' + queryString.stringify(query);
        }
        const response = await get(url, { signal });

        if (!response.ok) {
            // Parse the response to get error details
            const errorDetail = await response.json();
            // Log the error with details to Sentry
            Sentry.captureException(new Error(`Error fetching products: ${response.status} ${errorDetail.message}`), {
                extra: {
                    status: response.status,
                    url,
                    errorDetail,
                },
            });
            // Throw an error with a message and status code
            throw new Error(`HTTP error: ${response.status} ${errorDetail.message}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching products:', error);
        Sentry.captureException(error);
        throw error; // Re-throw the error to handle it further up the call stack if necessary
    }
}

/**
 * Fetch products
 * @param {*} options
 * @returns
 */
export async function getShopifyProducts(options = {}) {
    try {
        const { query, signal } = options;
        let url = '/product/shopify';
        if (query) {
            url += '?' + queryString.stringify(query);
        }
        const response = await get(url, { signal });

        if (!response.ok) {
            // Parse the response to get error details
            const errorDetail = await response.json();
            // Log the error with details to Sentry
            Sentry.captureException(new Error(`Error fetching products: ${response.status} ${errorDetail.message}`), {
                extra: {
                    status: response.status,
                    url,
                    errorDetail,
                },
            });
            // Throw an error with a message and status code
            throw new Error(`HTTP error: ${response.status} ${errorDetail.message}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching products:', error);
        Sentry.captureException(error);
        throw error; // Re-throw the error to handle it further up the call stack if necessary
    }
}
export async function fetchAvailableShopifyInventory(payload, options = {}) {
    try {
        const url = '/shopify/inventory';
        const response = await post(url, payload, options);

        if (!response.ok) {
            // Parse the response to get error details
            const errorDetail = await response.json();
            // Log the error with details to Sentry
            Sentry.captureException(new Error(`Error fetching products: ${response.status} ${errorDetail.message}`), {
                extra: {
                    status: response.status,
                    url,
                    errorDetail,
                },
            });
            // Throw an error with a message and status code
            throw new Error(`HTTP error: ${response.status} ${errorDetail.message}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching products:', error);
        Sentry.captureException(error);
        throw error; // Re-throw the error to handle it further up the call stack if necessary
    }
}

/**
 * Update Product
 * `/product/:id` - Updates specified product
 * This function makes an HTTP PUT request to the '/product/:id' endpoint to update product-related data.
 *
 * @returns {Promise<Object>}
 */
export async function updateProduct(id, payload, options) {
    const res = await put(`/product/${id}`, payload, options);
    return handleFetchResponse(res);
}

/**
 * Asynchronously creates products by sending a POST request to the specified URL.
 *
 * @param {Object[]} products - An array of product objects to be created.
 * @param {Object} options - Additional options for the POST request.
 *
 * @returns {Promise<Object>} A promise that resolves to the response object after handling.
 *
 * @throws {Error} Throws an error if the POST request fails.
 **/

export async function createProduct(product, options) {
    const url = '/product';
    const response = await post(url, product, options);
    if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}. ${response.message || 'Unknown error occurred'}`);
    }
    return response;
}

export async function upsertProducts(payload, options) {
    const url = '/upsert/product';
    const response = await post(url, payload, options);
    if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}. ${response.message || 'Unknown error occurred'}`);
    }
    return response;
}

/**
 * Asynchronously creates products by sending a POST request to the specified URL.
 *
 * @param {Object[]} products - An array of product objects to be created.
 * @param {Object} options - Additional options for the POST request.
 *
 * @returns {Promise<Object>} A promise that resolves to the response object after handling.
 *
 * @throws {Error} Throws an error if the POST request fails.
 **/

export async function createShopifyProducts(products, options) {
    const url = '/product/shopify';
    const response = await post(url, products, options);
    if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}. ${response.message || 'Unknown error occurred'}`);
    }
    return response;
}

/**
 * Update Business
 * `/business/:id` - Updates specified business with updateable fields
 * This function makes an HTTP PUT request to the '/business/:id' endpoint to update business-related data.
 *
 * @returns {Promise<Object>}
 */
export async function updateBusiness(id, update, options) {
    // Currently these are the only fields which can be updated in the API
    const {
        aboutUs,
        addresses,
        businessId,
        established,
        id: bId,
        imageLinks,
        name,
        termsAgreement,
        type,
        coverPhoto,
        minOrderValue,
    } = update;
    const payload = {
        aboutUs,
        addresses,
        businessId,
        established,
        id: bId,
        imageLinks,
        name,
        termsAgreement,
        type,
        coverPhoto,
        minOrderValue,
    };
    const res = await put(`/business/${id}`, payload, options);
    return handleFetchResponse(res);
}

/**
 * Patch Business
 * `/business/:id` - Patches specified business with updateable fields
 * This function makes an HTTP PATCH request to the '/business/:id' endpoint to patch business-related data.
 *
 * @returns {Promise<Object>}
 */
export async function patchBusiness(id, payload, options) {
    const res = await patch(`/business/${id}`, payload, options);
    return handleFetchResponse(res);
}

/**
 *
 * @param {string} id the unique product id to retrieve
 * @param {*} options
 * @returns
 */
export async function getProductById(id, options = {}) {
    try {
        const response = await get(`/product/${id}`, options);
        if (!response.ok) {
            const errorDetail = await response.json();
            Sentry.captureException(
                new Error(`Error fetching product by ID: ${response.status} ${errorDetail.message}`),
                {
                    extra: { id, status: response.status, errorDetail },
                }
            );
            throw new Error(`HTTP error: ${response.status} ${errorDetail.message}`);
        }
        return await response.json();
    } catch (error) {
        Sentry.captureException(error);
        throw error;
    }
}

export async function getProductVariants(options = {}) {
    try {
        const { query, signal } = options;
        let url = '/productVariant';
        if (query) {
            url += '?' + queryString.stringify(query);
        }
        const response = await get(url, { signal });
        if (!response.ok) {
            const errorDetail = await response.json();
            Sentry.captureException(
                new Error(`Error fetching product variants: ${response.status} ${errorDetail.message}`),
                {
                    extra: { query, status: response.status, errorDetail },
                }
            );
            throw json({ status: response.status, errorDetail });
        }
        return await response.json();
    } catch (error) {
        Sentry.captureException(error);
        throw error;
    }
}

export async function getProductVariantOptions(options = {}) {
    try {
        const { query, signal } = options;
        let url = '/productVariantOptions';
        if (query) {
            url += '?' + queryString.stringify(query);
        }
        const response = await get(url, { signal });
        if (!response.ok) {
            const errorDetail = await response.json();
            Sentry.captureException(
                new Error(`Error fetching product variant options: ${response.status} ${errorDetail.message}`),
                {
                    extra: { query, status: response.status, errorDetail },
                }
            );
            throw json({ status: response.status, errorDetail });
        }
        return await response.json();
    } catch (error) {
        Sentry.captureException(error);
        throw error;
    }
}

export async function refreshPaymentSettings(options = {}) {
    const { signal } = options;
    const res = await put('/payment/account/refresh', {}, { signal });
    return handleFetchResponse(res);
}

export async function getStripeLoginLink(options = {}) {
    const { signal } = options;
    const res = await get('/payment/account/login', { signal });
    return handleFetchResponse(res);
}

export async function getStripeOnboardingUrl(businessId, options = {}) {
    const { signal } = options;
    const res = await post('/payment/board', { businessId }, { signal });
    return handleFetchResponse(res);
}

/**
 * @deprecated
 * Fetch products
 * @param {object} payload contains info for shopify integration.
 * @param {*} options
 * @returns
 */
export async function integrateShopify(payload, options = {}) {
    try {
        const url = `/shopify/integrate?shop=${payload.storeName}.myshopify.com`;
        const response = await get(url, options);
        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error integrating Shopify Online Store', error);
        Sentry.captureException(error);
        throw error; // Re-throw the error to handle it further up the call stack if necessary
    }
}

/**
 * Disconnect Shopify Session
 * @param {string} id - The shop value of the Shopify Session.
 * @param {object} payload - The payload containing the data to update.
 * @param {object} options - Additional options for the request.
 * @returns {Promise<Object>} A promise that resolves to the response object.
 */
export async function disconnectShopifySession(id, payload, options = {}) {
    const { signal } = options;
    const res = await del(`/shopify/session/${id}`, payload, { signal });
    return handleFetchResponse(res);
}

/**
 * Update Shopify Session
 * @param {string} id - The shop value of the Shopify Session.
 * @param {object} payload - The payload containing the data to update.
 * @param {object} options - Additional options for the request.
 * @returns {Promise<Object>} A promise that resolves to the response object.
 */
export async function updateShopifySession(id, payload, options = {}) {
    const { signal } = options;
    const res = await put(`/shopify/session/${id}`, payload, { signal });
    return handleFetchResponse(res);
}

export async function payForOrder(orderId, options) {
    const endpoint = `/payment/order/${orderId}`;
    const response = await post(endpoint, {}, options);
    const jsonResponse = await response.json();
    if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}. ${jsonResponse.message || 'Unknown error occurred'}`);
    }
    return jsonResponse;
}

/**
 *
 * @param {string} id payment id
 * @param {object} update payload to update status, must include businessId too
 * @param {object} options fetch options
 * @returns
 */
export async function updatePayment(id, update, options) {
    const endpoint = `/payment/${id}`;
    const response = await put(endpoint, update, options);
    const jsonResponse = await response.json();
    if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}. ${jsonResponse.message || 'Unknown error occurred'}`);
    }
    return jsonResponse;
}

/**
 * Upload Image
 * `/image` - allows an authorized user to upload an image to an S3 bucket
 * This function makes an HTTP POST request to the `/core/image` endpoint to upload an image.
 * @param {File} imageFile - The image file to be uploaded.
 * @param {Object} options - Contains options such as signal for aborting the request.
 * @returns {Promise<Object>} A promise that resolves with the server response.
 */
export async function uploadImage(imageFile, options = {}) {
    const formData = new FormData();

    // Sanitize the file name before appending it to the form data
    const cleanFileName = sanitizeFileName(imageFile.name);
    const cleanFile = new File([imageFile], cleanFileName, {
        type: imageFile.type,
    });

    formData.append('image', cleanFile);

    const uploadApiUrl = config.get(DEV_THATCH_API_URL);

    try {
        const response = await post('/image', formData, options, uploadApiUrl);
        if (!response.ok) {
            const errorResponse = await response.json(); // Attempt to parse the response
            Sentry.captureException(new Error(`API response not OK: ${response.statusText}`));
            throw new Error(`HTTP error: ${response.status}. ${errorResponse.message || 'Unknown error occurred'}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Upload Image Error:', error);
        Sentry.captureException(error);
        throw error;
    }
}

/**
 * Confirm Business
 * Confirms the specified business by making a PUT request to the confirmBusiness endpoint.
 * This function relies on the 'put' utility, which automatically prepends the base API URL.
 * @param {number} id - The ID of the business to confirm.
 * @param {Object} options - Optional parameters such as signal for aborting the request.
 * @returns {Promise<Object>} A promise that resolves with the server response indicating the confirmation status.
 */
export async function confirmBusiness(id, options = {}) {
    const endpoint = `/confirmBusiness/${id}`;
    try {
        const response = await put(endpoint, {}, options);
        const jsonResponse = await response.json();
        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}. ${jsonResponse.message || 'Unknown error occurred'}`);
        }
        return jsonResponse;
    } catch (error) {
        console.error('Error confirming business:', error);
        throw error;
    }
}

/**
 * Link Existing Business from Invite
 * `/business/linkExistingBusiness` - Links existing business from invite
 * This function makes an HTTP POST request to the '/business/linkExistingBusiness' endpoint to link an invited business to the inviter
 *
 * @param {Object} body - The signature and inviteId of the business signing up.
 * @param {*} options
 * @returns {Promise<Object>} A promise that resolves with the server response.
 */
export async function linkExistingBusiness({ body, options = {} }) {
    const endpoint = `/business/linkExistingBusiness`;
    try {
        const response = await post(endpoint, body, options);
        const jsonResponse = await response.json();
        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}. ${jsonResponse.message || 'Unknown error occurred'}`);
        }
        return jsonResponse;
    } catch (error) {
        console.error('Error linking existing business:', error);
        throw error;
    }
}

/**
 * Link New Business from Invite
 * `/business/linkNewBusiness` - Links new business from invite
 * This function makes an HTTP POST request to the '/business/linkNewBusiness' endpoint to link an invited business to the inviter
 *
 * @param {Object} body - The firstName, lastName, signature, password and inviteId of the business signing up.
 * @param {*} options
 * @returns {Promise<Object>} A promise that resolves with the server response.
 */
export async function linkNewBusiness({ body, options }) {
    const endpoint = `/business/linkNewBusiness`;
    try {
        const response = await post(endpoint, body, options);
        const jsonResponse = await response.json();
        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}. ${jsonResponse.message || 'Unknown error occurred'}`);
        }
        return jsonResponse;
    } catch (error) {
        console.error('Error linking new business:', error);
        throw error;
    }
}

export async function createOrder(payload, options = {}) {
    const { signal } = options;
    const res = await post('/order', payload, { signal });
    return handleFetchResponse(res);
}

export async function getOrders(options = {}) {
    const { query } = options;
    let url = '/order';
    if (query) {
        url += '?' + queryString.stringify(query);
    }
    try {
        const response = await get(url, options);
        if (!response.ok) {
            const errorResponse = await response.json();
            Sentry.captureException(new Error(`Error fetching orders: ${response.status} ${errorResponse.message}`), {
                extra: { url, query, status: response.status, errorResponse },
            });
            throw new Error(`HTTP error: ${response.status}. ${errorResponse.message || 'Unknown error occurred'}`);
        }
        return await response.json();
    } catch (error) {
        Sentry.captureException(error);
        throw error;
    }
}

export async function getOrderById(orderId, options = {}) {
    const endpoint = `/order/${orderId}`;
    try {
        const response = await get(endpoint, options);
        if (!response.ok) {
            const errorResponse = await response.json();
            Sentry.captureException(
                new Error(`Error fetching order by ID: ${response.status} ${errorResponse.message}`),
                {
                    extra: { orderId, status: response.status, errorResponse },
                }
            );
            throw new Error(`HTTP error: ${response.status}. ${errorResponse.message || 'Unknown error occurred'}`);
        }
        return await response.json();
    } catch (error) {
        Sentry.captureException(error);
        throw error;
    }
}

/**
 * Update Order
 * Updates a specified order by making a PUT request to the /order endpoint.
 * This function relies on the 'put' utility, which automatically prepends the base API URL.
 * @param {string} orderId - The orderId to update.
 * @param {Object} payload - The updated order.
 * @param {Object} options - Optional parameters such as signal for aborting the request.
 * @returns {Promise<Object>} A promise that resolves with the server response indicating the confirmation status.
 */
export async function updateOrder(orderId, payload, options = {}) {
    const path = `/order/${orderId}`;
    try {
        const res = await put(path, payload, options);
        const data = await res.json();
        return {
            ok: res.ok,
            data,
        };
    } catch (error) {
        console.error('Error updating order:', error);
        Sentry.captureException(error);
        return { ok: false };
    }
}

export async function updateOrderStatus(orderId, update, options = {}) {
    const path = '/order/status/' + orderId;
    try {
        const res = await put(path, update, options);
        const data = await res.json();
        return {
            ok: res.ok,
            data,
        };
    } catch (error) {
        console.error('Error updating order status:', error);
        Sentry.captureException(error);
        return { ok: false };
    }
}

export async function invite(businessId, email, businessName, options = {}) {
    const endpoint = `/business/invite`;
    try {
        const body = {
            email,
            businessId,
            name: businessName,
        };
        const response = await post(endpoint, body, options);
        const jsonResponse = await response.json();
        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}. ${jsonResponse.message || 'Unknown error occurred'}`);
        }
        return jsonResponse;
    } catch (error) {
        console.error('Error inviting business to Thatch:', error);
        throw error;
    }
}

/**
 * Get Categories
 * Gets Categories associated with an ID
 * @param {string} categoryId - The ID of the category.
 * @param {Object} options - Optional parameters such as signal for aborting the request.
 */
export async function getCategories(categoryId, options = {}) {
    const endpoint = `/categoryV2/${categoryId}`;
    try {
        const response = await get(endpoint, options);
        const jsonResponse = await response.json();
        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}. ${jsonResponse.message || 'Unknown error occurred'}`);
        }
        return jsonResponse;
    } catch (error) {
        console.error('Error getting category:', error);
        throw error;
    }
}

export async function frontEndSetup(options = {}) {
    const endpoint = '/frontend/setup';
    try {
        const response = await post(endpoint, options);
        const data = await response.json();
        return {
            ok: response.ok,
            data,
        };
    } catch (error) {
        console.error('Error setting up the frontend:', error);
        Sentry.captureException(error);
        return { ok: false };
    }
}

/**
 * @typedef Note
 * @property {number} id
 * @property {number} orderId
 * @property {string} businessId
 * @property {string} content
 * @property {string} createdByBusinessId
 * @property {number} createdByUser
 * @property {string} createdAt
 * @property {string} updatedAt
 */

/**
 * Create Note
 * @param {Note} note
 * @param {Object} options
 * @returns {Promise<Object>}
 */
export async function createOrderNote(note, options) {
    const path = '/orderNote';
    const res = await post(path, note, options);
    return handleFetchResponse(res);
}
