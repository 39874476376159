/**
 * Groups order items by product
 * @param {*} order
 * @param {[]} order.orderItems
 * @returns
 */
export const groupOrderItemsByProduct = (order) => {
    return (
        order?.orderItems?.reduce((acc, item) => {
            acc[item.productVariant.productId] = [...(acc[item.productVariant.productId] || []), item];
            return acc;
        }, {}) ?? {}
    );
};

/**
 * Groups the orderItem products by productId
 * @param {*} order
 * @param {[]} order.orderItems
 * @returns
 */
export const groupProductsByProductId = (order) => {
    const productMap = {};
    order?.orderItems?.forEach((item) => {
        productMap[item?.productVariant?.productId] = item?.productVariant?.product;
    });
    return productMap;
};

/**
 * Selects a product by its productId from the grouped products
 * @param {Object} groupedProducts - The result of groupProductsByProductId
 * @param {number} productId - The ID of the product to select
 * @returns {Object|null} - The selected product or null if not found
 */
export const selectProductById = (groupedProducts, productId) => {
    return groupedProducts[productId] || null;
};

/**
 * Selects an item by its productId from the grouped items
 * @param {Object} groupedItems - The result of groupOrderItemsByProduct
 * @param {number} productId - The ID of the product to select
 * @returns {Object|null} - The selected item or null if not found
 */
export const selectItemsByProductId = (groupedItems, productId) => {
    return groupedItems[productId] || [];
};

export const selectTotalQuantityOfProductId = (groupedItems, productId) => {
    const items = selectItemsByProductId(groupedItems, productId);
    return items.reduce((acc, item) => acc + Number(item.quantity), 0);
};

/**
 * A default tag sorting function, arrage by color first
 * @param {*} productVariant
 * @param {[]} productVariant.variantTags
 * @returns
 */
export const sortTags = (productVariant) => {
    const { variantTags = [] } = productVariant;
    const colorTags = variantTags.filter((tag) => tag?.name?.toLowerCase() === 'color');
    const otherTags = variantTags.filter((tag) => tag?.name?.toLowerCase() !== 'color');
    return [...colorTags, ...otherTags];
};
