import React, { useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import {
    checkRequiredFieldValues,
    cleanData,
    cleanVariants,
    requiredFields,
    productShape,
} from '../ProductVariantTable/productUtil.js';
import { post, put } from '../../../../../providers/fetchUtil.js';
import Stack from '@mui/material/Stack';
import BackButton from '../../../../../components/BackButton/BackButton.jsx';
import BrandProductEditButtons from '../../../../../components/BrandProductEditButtons/BrandProductEditButtons.jsx';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MissingFieldsModal from '../../ProductModals/MissingFieldsModal.jsx';
import ProductVariantEditPage from './ProductVariantEditPage/ProductVariantEditPage.jsx';
import Box from '@mui/material/Box';

export default function ProductVariantEdit() {
    let { product = productShape } = useLoaderData() || {};
    const { productId } = useParams();
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [newProduct, setNewProduct] = useState(product);
    const [variantFieldsValidity, setVariantFieldsValidity] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [missingFieldsModalOpen, setMissingFieldsModalOpen] = useState(false);

    const handleVariantChange = (variants) => {
        const copyOfNewProduct = _.cloneDeep(newProduct);
        copyOfNewProduct.productVariants = variants;
        setNewProduct(copyOfNewProduct);
    };

    function checkRequiredFieldValuesVariants(product) {
        const variants = product?.productVariants || [];
        const req = variants.reduce((acc, variant) => {
            const invalid = checkRequiredFieldValues(variant, requiredFields);
            const name = variant.variantTags.map((attr) => attr.value).join(' / ');
            acc[name] = invalid;
            return acc;
        }, {});

        const flatten = _.transform(
            req,
            (result, value, key) => {
                _.forOwn(value, (subValue, subKey) => {
                    if (subValue === true) {
                        result[`${key}: ${subKey}`] = true;
                    }
                });
            },
            {}
        );

        return flatten;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const rq = checkRequiredFieldValuesVariants(newProduct);
        const hasErrors = _.keys(_.pickBy(rq, _.identity));

        if (!_.isEmpty(hasErrors)) {
            setMissingFieldsModalOpen(true);
            return;
        }

        let productData = {
            ...newProduct,
        };
        delete productData.productVariantTagOptions;

        productData.productVariants = cleanVariants(productData?.productVariants);

        productData = cleanData(productData);
        setIsSubmitting(true);

        try {
            // Use POST for create and PUT for update based on the presence of productId
            const url = productId ? `/product/${productId}` : '/product';
            const payload = productData;
            const response = productId ? await put(url, payload) : await post(url, payload);
            const data = await response.json();

            if (!response.ok) {
                throw new Error(`Error ${response.status}: ${data.message || 'Failed to save product data'}`);
            }

            setSnackbarMessage('Product Variants Saved Successfully');
            setSnackbarType('success');
            setSnackbarOpen(true);
            product = newProduct;
            navigate('/brand/products');
        } catch (error) {
            setSnackbarMessage(error.message);
            setSnackbarType('error');
            setSnackbarOpen(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    function handleVariantValidity(variant) {
        const index = _.findIndex(variantFieldsValidity, { id: variant.id });
        const variantsValidityClone = _.cloneDeep(variantFieldsValidity);
        if (index > -1) {
            variantsValidityClone[index] = variant;
            setVariantFieldsValidity(variantsValidityClone);
        } else {
            variantsValidityClone.push(variant);

            setVariantFieldsValidity(variantsValidityClone);
        }
    }

    return (
        <>
            <Box>
                <Stack spacing={1}>
                    <BackButton />
                    <BrandProductEditButtons
                        isNewProduct={product?.id === ''}
                        isSubmitting={isSubmitting}
                        productChanged={true}
                        onSave={handleSubmit}
                    />
                </Stack>
                <ProductVariantEditPage
                    variantFieldsValidity={variantFieldsValidity}
                    onValidityChange={handleVariantValidity}
                    product={newProduct}
                    onChange={handleVariantChange}
                />
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                    <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
            <MissingFieldsModal
                open={missingFieldsModalOpen}
                missingFields={checkRequiredFieldValuesVariants(newProduct, requiredFields)}
                onClose={() => setMissingFieldsModalOpen(false)}
            />
        </>
    );
}
