import * as React from 'react';
import { Link } from 'react-router-dom';
import ConnectionCard from './ConnectionCard';
import PropTypes from 'prop-types';

export default function ConnectionLink({ businessType, business }) {
    if (businessType === 'Brand') {
        // The current design does not require anything to happen when a Brand
        // connection is clicked.
        return <ConnectionCard business={business} />;
    }
    if (businessType === 'Retail') {
        return (
            <Link to={`/retail/connections/${business.id}/products`} style={{ textDecoration: 'none' }}>
                <ConnectionCard business={business} />
            </Link>
        );
    }
}

ConnectionLink.propTypes = {
    businessType: PropTypes.string.isRequired,
    business: PropTypes.object.isRequired,
};
