import * as React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { Button, Stack, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default function QuantityInput(props) {
    const [error, setError] = React.useState();
    const theme = useTheme();

    const { onChange, value, min = 1, max = 9999 } = props;
    const increment = (e) => {
        let val = Number(value) + 1;
        if (val >= max) {
            val = max;
        }
        if (onChange) {
            onChange(e, val);
        }
    };
    const decrement = (e) => {
        let val = Number(value) - 1;
        if (val <= min) {
            val = min;
        }
        if (onChange) {
            onChange(e, val);
        }
    };

    const handleOnChange = (values, sourceInfo) => {
        if (values.floatValue !== undefined) {
            setError(false);
            if (onChange) {
                onChange(sourceInfo.event, values.floatValue);
            }
        } else {
            setError(true);
        }
    };

    const handleOnBlur = (e) => {
        if (e.target.value === '') {
            setError(true);
        } else {
            setError(false);
        }
    };

    const inputDigits = `${value}`.length > 2 ? `${value}`.length : 2;
    return (
        <Stack
            className={error ? 'error' : ''}
            direction="row"
            sx={{
                borderRadius: 2,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'common.Fog',
                '& input': { outline: 'none' },
            }}>
            <Button
                sx={{
                    minWidth: 0,
                    pl: '10px',
                    pr: '3px',
                    border: 'none',
                    '&:hover': {
                        border: 'none',
                    },
                }}
                onClick={decrement}
                size="micro"
                variant="outlined">
                <RemoveIcon sx={{ fontSize: '16px' }} />
            </Button>
            <NumericFormat
                required
                onBlur={handleOnBlur}
                allowNegative={false}
                onValueChange={handleOnChange}
                isAllowed={(values) => {
                    const { floatValue, value } = values;
                    return (floatValue >= min && floatValue <= max) || (min === 1 && floatValue !== 0) || value === '';
                }}
                style={{
                    paddingTop: 5,
                    textAlign: 'center',
                    border: 'none',
                    fontFamily: 'SalisburyBold',
                    lineHeight: '22px',
                    color: theme.palette.common.Tide,
                }}
                size={inputDigits}
                value={value}
                valueIsNumericString
            />
            <Button
                sx={{
                    minWidth: 0,
                    pr: '10px',
                    pl: '3px',
                    border: 'none',
                    '&:hover': {
                        border: 'none',
                    },
                }}
                onClick={increment}
                size="micro"
                variant="outlined">
                <AddIcon sx={{ fontSize: '16px' }} />
            </Button>
        </Stack>
    );
}

QuantityInput.propTypes = {
    max: PropTypes.number,
    min: PropTypes.number,
    onChange: PropTypes.func,
    value: PropTypes.number,
};
