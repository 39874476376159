import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { redirect, useFetcher, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import _ from 'lodash';
import LoadingButton from '../../components/LoadingButton/LoadingButton.jsx';
import thatchLogo from '../../assets/ThatchLogo--Tide.svg';
import { resetPassword } from 'aws-amplify/auth';
import { useMediaQuery, useTheme } from '@mui/material';
import FormInputLabel from '../../components/FormInputLabel/FormInputLabel.jsx';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FingerprintIcon from '../../icons/FingerprintIcon.jsx';

export async function action({ request }) {
    try {
        const formData = await request.formData();
        const username = formData.get('email');
        const response = await resetPassword({ username });
        if (response.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
            localStorage.setItem('passwordResetEmail', username);
            return redirect('/forgotPassword/confirm');
        } else {
            return response;
        }
    } catch (error) {
        return { error: error.message };
    }
}

export default function ForgotPassword() {
    const theme = useTheme();
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const fetcher = useFetcher();
    const isSendingCode = fetcher.state === 'submitting';
    const { t } = useTranslation();

    const confirmEnabled = () => {
        return !_.isEmpty(email);
    };

    const handleBackToSignIn = () => {
        navigate('/login');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
                width: '100%',
            }}>
            <fetcher.Form method="post">
                <Stack gap={10} sx={{ height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Stack
                        gap={2}
                        sx={{
                            alignItems: 'flex-start',
                            width: {
                                xs: '90%',
                                sm: '400px',
                            },
                            mt: 15,
                        }}>
                        <FingerprintIcon sx={{ color: 'common.Midnight', width: 48, height: 48 }} />
                        <Stack gap={2} sx={{ width: '100%', justifyContent: 'center' }}>
                            <Typography variant="displayMd">{t('ForgotPassword.forgotPasswordLabel')}</Typography>
                            <Typography sx={{ color: theme.palette.common.LightGray }}>
                                {t('ForgotPassword.forgotPasswordPrompt')}
                            </Typography>
                            <Stack gap={3}>
                                <FormInputLabel text={t('ForgotPassword.emailLabel')}>
                                    <TextField
                                        fullWidth
                                        placeholder={t('ForgotPassword.emailPlaceholder')}
                                        name="email"
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                    />
                                </FormInputLabel>
                                <Stack
                                    gap={1}
                                    direction="row"
                                    sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <Button
                                        onClick={handleBackToSignIn}
                                        variant="outlined"
                                        color="secondary"
                                        size={isSmBreakpoint ? undefined : 'sm'}
                                        sx={{ flex: 1 }}>
                                        {t('ForgotPassword.backToSignInButton')}
                                    </Button>
                                    <LoadingButton
                                        disabled={!confirmEnabled()}
                                        isLoading={isSendingCode}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size={isSmBreakpoint ? undefined : 'sm'}
                                        sx={{ flex: 1 }}>
                                        {t('ForgotPassword.resetPasswordLabel')}
                                    </LoadingButton>
                                </Stack>
                                {fetcher.data?.error ? (
                                    <Typography
                                        sx={{
                                            color: theme.palette.common.Alert,
                                        }}>
                                        {'Error: Failed to reset password. Please check your email and try again.'}
                                    </Typography>
                                ) : null}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box component="img" src={thatchLogo} alt="Logo" sx={{ height: '25.7px', width: '150px', mb: 5 }} />
                </Stack>
            </fetcher.Form>
        </Box>
    );
}
