import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SoftBG, White, BoxShadow } from '../../../theme.js';
import { NumericFormat } from 'react-number-format';
import * as React from 'react';
import BusinessLogo from '../../../components/BusinessLogo.jsx';
import { Avatar, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import ReceiptIcon from '../../../icons/ReceiptIcon.jsx';
import Stack from '@mui/material/Stack';

export default function OrderCardHeader(props) {
    const order = props?.order;
    const business = props?.business;
    const { t } = useTranslation();
    const isXxs = useMediaQuery('(max-width:500px)');

    return (
        <Box
            sx={{
                backgroundColor: SoftBG,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', md: 'row' },
                borderRadius: '16px 16px 0 0',
                padding: '15px',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    marginBottom: { xs: 2, md: 0 },
                }}>
                <Avatar
                    variant="rounded"
                    aria-label="business logo"
                    sx={{
                        boxShadow: BoxShadow,
                        width: 60,
                        height: 60,
                        marginRight: '10px',
                        backgroundColor: White,
                    }}>
                    <BusinessLogo business={business} sx={{ width: 56, backgroundColor: White }} />
                </Avatar>
                <Box>
                    <Typography variant="displaySm">{business?.name}</Typography>
                    <Stack gap={1} direction="row">
                        <ReceiptIcon />
                        <Typography>{'Order #' + order?.id}</Typography>
                    </Stack>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: isXxs ? 'space-between' : 'flex-end',
                    flexWrap: 'wrap',
                    gap: 2,
                    flex: '2 0 auto',
                    '& p': { fontSize: '.75rem' },
                }}>
                <Box>
                    <Typography variant="label">{t('RetailOrderListItem.dateCreated')}</Typography>
                    <Typography>{format(new Date(order.createdAt), 'MMM d, yyyy')}</Typography>
                </Box>
                <Box>
                    <Typography variant="label">{t('RetailOrderListItem.totalLabel')}</Typography>
                    <Typography>
                        <NumericFormat
                            prefix="$"
                            displayType="text"
                            value={order?.total}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="label">{t('RetailOrderListItem.shipToLabel')}</Typography>
                    {order?.addresses?.map((address) => (
                        <Box key={address.street1}>
                            <Typography>
                                {address?.city}, {address?.state}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

OrderCardHeader.propTypes = {
    business: PropTypes.any,
    order: PropTypes.any,
};
