import { Authenticator } from '@aws-amplify/ui-react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, redirect, useLoaderData, useNavigation } from 'react-router-dom';
import { CartProvider } from '../../cart/CartContext.jsx';
import LoadingModal from '../../components/LoadingModal/LoadingModal.jsx';
import ThatchAppBar from '../../components/ThatchAppBar/ThatchAppBar.jsx';
import { BusinessTypes } from '../../constants/BusinessTypes';
import * as authProvider from '../../providers/auth';
import * as businessProvider from '../../providers/businessProvider.js';
import * as userProvider from '../../providers/userProvider.js';
import { useSignOut } from '../../hooks/useSignOut.js';
import { BRAND_HOME_URL } from '../routesUtil.js';

export async function loader({ request }) {
    const { signal } = request;
    const isAuthenticated = await authProvider.authenticate();
    if (!isAuthenticated) {
        let loginUrl = '/login';
        const { pathname, search } = new URL(request.url);
        if (pathname.includes('/retail') || pathname.includes('/brand')) {
            const params = new URLSearchParams();
            params.set('from', pathname + search);

            // TODO may need to update this to work with future routes
            loginUrl += `?${params.toString()}`;
        }
        return redirect(loginUrl);
    }

    const [user, business] = await Promise.all([
        userProvider.getUser({ signal }),
        businessProvider.getBusiness({ signal }),
    ]);

    if (!business?.type) {
        return redirect('/business/setup');
    }
    const { pathname } = new URL(request.url);
    const isRetail = business?.type === BusinessTypes.RETAIL;
    const isBrand = business?.type === BusinessTypes.BRAND;
    if (pathname === '/') {
        let url;
        if (isRetail) {
            url = '/retail/connections';
        } else if (isBrand) {
            url = BRAND_HOME_URL;

            const hasPaymentMethodVerified = business?.settings?.paymentProviderSettings?.paymentProviderAccountRef;
            if (!hasPaymentMethodVerified) {
                url = '/business/setup/stripe';
            }
        }
        if (url) {
            return redirect(url);
        }
    }
    // TODO clean this up
    const userById = userProvider.getUserById({ id: user.user.id, signal });

    const allBusinesses = [business, ...(business?.connectedBusiness || [])];
    return {
        business,
        businessesByBusinessId: allBusinesses?.reduce((acc, curr) => {
            return {
                ...acc,
                [curr.businessId]: curr,
            };
        }, {}),
        businessesById: allBusinesses?.reduce((acc, curr) => {
            return {
                ...acc,
                [curr.id]: curr,
            };
        }, {}),
        defaultAddresses: business?.addresses?.sort(function (x, y) {
            return x.label === 'Primary' ? -1 : y.label === 'Primary' ? 1 : 0;
        }),
        user,
        userById,
        thatchUser: await userById,
    };
}

export default function Root() {
    const data = useLoaderData();
    const { t } = useTranslation();
    const navigation = useNavigation();
    const isLoading = navigation.state === 'loading';
    const signOut = useSignOut();

    const business = data?.business ?? {};
    const isOnboardingComplete =
        business?.type !== BusinessTypes.BRAND || business?.settings?.paymentProviderSettings?.onboardingComplete;

    return (
        <Authenticator.Provider>
            <CartProvider>
                <Box id="App_Root">
                    <ThatchAppBar signOut={signOut} />
                    {isOnboardingComplete ? null : (
                        <Box sx={{ position: 'relative', minHeight: { xs: 77, sm: 52 } }}>
                            <Alert
                                sx={{ position: 'fixed', width: '100vw', zIndex: 10 }}
                                severity="warning"
                                action={
                                    <Button variant="secondary" href="/brand/settings/payment">
                                        {t('Root.paymentLinkLabel')}
                                    </Button>
                                }>
                                {t('Root.onboardingIncomplete')}{' '}
                            </Alert>
                        </Box>
                    )}
                    <Outlet />
                    {isLoading && <LoadingModal open={isLoading} />}
                </Box>
            </CartProvider>
        </Authenticator.Provider>
    );
}
