import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from '../PhoneInput/PhoneInput.jsx';
import CircleXIcon from '../../icons/CircleXIcon.jsx';
import IconButton from '@mui/material/IconButton';
import { LightGray, Midnight } from '../../theme.js';
import FormInputLabel from '../FormInputLabel/FormInputLabel.jsx';
import StateSelect from '../StateSelect/StateSelect.jsx';
import { isValidEmail } from '../../helpers/HelperFunctions.jsx';
import { matchIsValidTel } from 'mui-tel-input';

export default function AddressForm(props) {
    const { address = {}, onSubmit, onClose } = props;
    const isEditing = Boolean(address?.street1 && address?.city && address?.state && address?.postalCode);
    const { t } = useTranslation();
    const responsiveDirection = { xs: 'column', sm: 'row' };
    const responsiveStyle = {
        sx: {
            alignItems: {
                xs: 'flex-start',
                sm: 'center',
            },
            justifyContent: {
                xs: 'flex-start',
                sm: 'center',
            },
            width: '100%',
        },
    };

    // State variables for each input field
    const [addressLabel, setAddressLabel] = useState(address.label || '');
    const [addressLine, setAddressLine] = useState(address.street1 || '');
    const [city, setCity] = useState(address.city || '');
    const [state, setState] = useState(address.state || '');
    const [zipCode, setZipCode] = useState(address.postalCode || '');
    const [email, setEmail] = useState(address.emails?.[0]?.default || '');
    const [phone, setPhone] = useState(address.phones?.[0]?.primary || '');
    const [secondaryPhone, setSecondaryPhone] = useState(address.phones?.[0]?.secondary || '');
    const [isPrimary, setIsPrimary] = useState(address.isPrimary || false);
    const isInitiallyPrimary = address.isPrimary || false;

    // Validation state
    const [isFormValid, setIsFormValid] = useState(false);

    // Form validation logic
    useEffect(() => {
        const isValid =
            addressLabel.trim() !== '' &&
            addressLine.trim() !== '' &&
            city.trim() !== '' &&
            state !== '' &&
            zipCode.trim() !== '' &&
            isValidEmail(email) &&
            matchIsValidTel(phone);

        setIsFormValid(isValid);
    }, [addressLabel, addressLine, city, state, zipCode, email, phone]);

    // Event handlers for input changes
    const handleAddressLabelChange = (e) => setAddressLabel(e.target.value);
    const handleAddressLineChange = (e) => setAddressLine(e.target.value);
    const handleCityChange = (e) => setCity(e.target.value);
    const handleStateChange = (e) => setState(e.target.value);
    const handleZipCodeChange = (e) => setZipCode(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePhoneChange = (value) => setPhone(value);
    const handleSecondaryPhoneChange = (value) => setSecondaryPhone(value);
    const handlePrimaryChange = (e) => setIsPrimary(e.target.checked);

    const submit = () => {
        const editedAddress = {
            label: addressLabel,
            street1: addressLine,
            city,
            state,
            postalCode: zipCode,
            email,
            phoneNumber: phone,
            secondaryPhoneNumber: secondaryPhone,
            isPrimaryAddress: isPrimary,
        };
        onSubmit(editedAddress);
    };

    return (
        <Stack gap={3} sx={{ width: '100%', maxWidth: 600, my: 1 }}>
            <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="textLg" sx={{ fontWeight: 'bold' }}>
                    {isEditing ? t('AddAddressInput.editTitleLabel') : t('AddAddressInput.titleLabel')}
                </Typography>
                <IconButton onClick={onClose} sx={{ padding: 0 }}>
                    <CircleXIcon sx={{ color: Midnight }} />
                </IconButton>
            </Stack>
            <Stack gap={2}>
                <FormInputLabel text={t('AddAddressInput.addressLabelInputLabel')}>
                    <TextField
                        fullWidth
                        value={addressLabel}
                        onChange={handleAddressLabelChange}
                        placeholder={t('AddAddressInput.addressLabelInputPlaceholder')}
                    />
                </FormInputLabel>
                <Stack gap={2} direction={responsiveDirection} sx={responsiveStyle}>
                    <Box sx={{ width: '60%', flexGrow: 1 }}>
                        <FormInputLabel text={t('AddAddressInput.addressInputLabel')}>
                            <TextField
                                fullWidth
                                value={addressLine}
                                onChange={handleAddressLineChange}
                                placeholder={t('AddAddressInput.addressInputPlaceholder')}
                            />
                        </FormInputLabel>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <FormInputLabel text={t('AddAddressInput.cityInputLabel')}>
                            <TextField
                                fullWidth
                                value={city}
                                onChange={handleCityChange}
                                placeholder={t('AddAddressInput.cityInputPlaceholder')}
                            />
                        </FormInputLabel>
                    </Box>
                </Stack>
                <Stack gap={2} direction="row" sx={{ maxWidth: 400 }}>
                    <Box sx={{ width: '50%', flex: 1 }}>
                        <FormInputLabel text={t('AddAddressInput.stateInputLabel')}>
                            <StateSelect defaultValue={state} onChange={handleStateChange} />
                        </FormInputLabel>
                    </Box>
                    <Box sx={{ flex: 1, width: '35%' }}>
                        <FormInputLabel text={t('AddAddressInput.zipCodeInputLabel')}>
                            <TextField
                                fullWidth
                                value={zipCode}
                                onChange={handleZipCodeChange}
                                placeholder={t('AddAddressInput.zipCodeInputPlaceholder')}
                            />
                        </FormInputLabel>
                    </Box>
                </Stack>
                <Box sx={{ maxWidth: 400 }}>
                    <FormInputLabel text={t('AddAddressInput.emailInputLabel')}>
                        <TextField
                            fullWidth
                            value={email}
                            onChange={handleEmailChange}
                            placeholder={t('AddAddressInput.emailInputPlaceholder')}
                        />
                    </FormInputLabel>
                </Box>
                <Stack gap={2} direction={responsiveDirection} sx={{ ...responsiveStyle }}>
                    <Box sx={{ flex: 1 }}>
                        <FormInputLabel text={t('AddAddressInput.phoneInputLabel')}>
                            <PhoneInput value={phone} onChange={handlePhoneChange} fullWidth />
                        </FormInputLabel>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <FormInputLabel text={t('AddAddressInput.secondaryPhoneInputLabel')}>
                            <PhoneInput value={secondaryPhone} onChange={handleSecondaryPhoneChange} fullWidth />
                        </FormInputLabel>
                    </Box>
                </Stack>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <Checkbox checked={isPrimary} onChange={handlePrimaryChange} disabled={isInitiallyPrimary} />
                    <Typography variant="textSm" sx={{ color: LightGray }}>
                        {t('AddAddressInput.checkBoxPrompt')}
                    </Typography>
                </Stack>
                <Button onClick={submit} variant="contained" disabled={!isFormValid}>
                    {isEditing
                        ? t('AddAddressInput.submitExistingAddressPrompt')
                        : t('AddAddressInput.submitNewAddressPrompt')}
                </Button>
            </Stack>
        </Stack>
    );
}

AddressForm.propTypes = {
    address: PropTypes.shape({
        label: PropTypes.string,
        street1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        postalCode: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        secondaryPhone: PropTypes.string,
        isPrimary: PropTypes.bool,
    }),
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func,
};
