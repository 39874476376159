import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ThatchLogoMiniBorderedIcon(props) {
    return (
        <SvgIcon component="svg" sx={props?.sx} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="thatch logo">
                <ellipse id="Ellipse 68" cx="20.2337" cy="19.8932" rx="18.8372" ry="18.6207" fill="#5C7B7D" />
                <g id="Group">
                    <path
                        id="Vector"
                        d="M30.6378 18.8058C30.6378 19.8613 29.7647 20.5704 28.6942 20.3807L25.3187 19.7816V31.7295C25.3187 32.7849 24.4456 33.5682 23.3751 33.4665C22.3074 33.3648 21.4316 32.4166 21.4316 31.3612V19.0917L19.3934 18.7289V14.8892L21.4316 15.252V12.4072L25.3159 13.0971V15.9418L28.6914 16.541C29.7591 16.7307 30.635 17.7504 30.635 18.8058H30.6378ZM9.94531 15.3674C9.94531 16.4228 10.8184 17.4425 11.8889 17.6322L13.7657 17.9648V14.1251L11.8889 13.7925C10.8212 13.6028 9.94531 14.312 9.94531 15.3674Z"
                        fill="#E4E4E4"
                    />
                    <path
                        id="Vector_2"
                        d="M29.9843 25.1444C29.9843 26.1998 29.1112 26.9089 28.0407 26.7193L26.1723 26.3867V22.547L28.0407 22.8796C29.1085 23.0692 29.9843 24.0889 29.9843 25.1444ZM18.4953 9.15335C18.4953 8.09792 17.6222 7.14967 16.5517 7.04797C15.484 6.94628 14.6082 7.72686 14.6082 8.78505V20.7329L11.2326 20.1338C10.1649 19.9441 9.28906 20.6532 9.28906 21.7087C9.28906 22.7641 10.1621 23.7838 11.2326 23.9735L14.6082 24.5727V27.4174L18.4925 28.1073V25.2625L20.5306 25.6253V21.7856L18.4925 21.4228V9.15335H18.4953Z"
                        fill="#E4E4E4"
                    />
                </g>
                <path
                    id="Vector_3"
                    d="M19.9668 39.9923C14.6338 39.9923 9.62053 37.9391 5.84739 34.2121C2.07703 30.4851 0 25.5267 0 20.255C0 14.9833 2.07703 10.0277 5.84739 6.30068C9.61774 2.57367 14.6338 0.520508 19.9668 0.520508C25.2998 0.520508 30.313 2.57367 34.0834 6.30068C37.8537 10.0277 39.9307 14.9833 39.9307 20.255C39.9307 25.5267 37.8537 30.4823 34.0834 34.2121C30.313 37.9419 25.2998 39.9923 19.9668 39.9923ZM19.9668 3.5439C15.4512 3.5439 11.2054 5.28373 8.01062 8.43905C4.8186 11.5971 3.05855 15.7914 3.05855 20.2578C3.05855 24.7241 4.8186 28.9184 8.01062 32.0765C11.2026 35.2318 15.4484 36.9716 19.9668 36.9716C24.4851 36.9716 28.7281 35.2318 31.9229 32.0765C38.5155 25.5597 38.5155 14.9558 31.9229 8.43905C28.7309 5.28373 24.4851 3.5439 19.9668 3.5439Z"
                    fill="white"
                />
            </g>
        </SvgIcon>
    );
}

ThatchLogoMiniBorderedIcon.propTypes = {
    sx: PropTypes.any,
};
