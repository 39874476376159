import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetcher, useLoaderData } from 'react-router-dom';
import { renderCurrencyCell } from '../../helpers/HelperFunctions';
import ProductIcon from '../ProductIcon';
import SearchBar from '../SearchBar/SearchBar';
import ProductPickerMobile from './ProductPicker.mobile';

export default function ProductPicker(props) {
    const data = useLoaderData();
    const fetcher = useFetcher();
    const anchorEl = useRef();
    const [searchValue, setSearchValue] = useState('');
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState(data?.rows || []);
    const theme = useTheme();
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const isLgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));
    const { t } = useTranslation();

    useEffect(() => {
        if (fetcher.data && fetcher.state === 'idle') {
            setOptions(fetcher?.data?.rows);
        }
    }, [fetcher]);

    const queryProducts = useMemo(
        () =>
            debounce((value) => {
                const queryString = new URLSearchParams();
                queryString.set('q', value);
                const query = `?${queryString.toString()}`;
                fetcher.load(query);
            }, 400),
        []
    );

    useEffect(() => {
        if (fetcher.state === 'idle') {
            queryProducts(searchValue);
        }
    }, [searchValue, queryProducts]);

    const handleSearchInputChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    const handleFocus = () => {
        setOpen(true);
    };

    const handleAddButtonClick = (params) => () => {
        if (props?.onAddProduct) {
            props.onAddProduct(params.row);
        }
    };

    const renderAddButtonCell = (params) => {
        const selectedProducts = props?.value || [];
        const isAdded = selectedProducts.find((p) => params.row.id === p.id);
        if (isAdded) {
            return (
                <IconButton color="success">
                    <CheckIcon />
                </IconButton>
            );
        }
        return (
            <IconButton color="info" onClick={handleAddButtonClick(params)}>
                <AddIcon />
            </IconButton>
        );
    };

    const handleOnClose = () => {
        setOpen(false);
        setSearchValue('');
    };

    const columns = [
        {
            field: 'imageLinks',
            headerName: '',
            renderCell: (params) => (
                <>
                    <ProductIcon
                        key={params.id}
                        src={params.value?.[0]}
                        objectFit="cover"
                        sx={{ width: '45px', height: '45px' }}
                    />
                </>
            ),
        },
        {
            field: 'mpn',
            headerClassName: 'thatch-table-header--blue',
            headerName: t('ProductPicker.mpnLabel'),
            width: isLgBreakpoint ? 200 : undefined,
        },
        {
            field: 'title',
            headerClassName: 'thatch-table-header--blue',
            headerName: t('ProductPicker.productNameLabel'),
            width: isLgBreakpoint ? 300 : undefined,
        },
        {
            field: 'wholesale',
            headerClassName: 'thatch-table-header--blue',
            headerName: t('ProductPicker.wholesaleLabel'),
            width: isLgBreakpoint ? 150 : undefined,
            renderCell: renderCurrencyCell,
        },
        {
            field: 'add',
            headerName: '',
            renderCell: renderAddButtonCell,
        },
    ];

    if (!isSmBreakpoint) {
        return <ProductPickerMobile {...props} />;
    }
    return (
        <ClickAwayListener onClickAway={handleOnClose}>
            <Box data-testid="product-picker" sx={props.sx}>
                <SearchBar
                    fullWidth
                    InputBaseRef={anchorEl}
                    {...props?.SearchBarProps}
                    placeholder={props.placeholder}
                    onFocus={handleFocus}
                    onChange={handleSearchInputChange}
                    value={searchValue}
                />
                <Popper id="product-picker" open={open} anchorEl={anchorEl.current} placement="bottom-start">
                    <Card elevation={4} sx={{ zIndex: 100 }}>
                        <CardContent sx={{ width: '100%' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton onClick={handleOnClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <DataGridPro
                                columns={columns}
                                rows={options}
                                hideFooter
                                sx={{
                                    border: 'none',
                                    '& .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                    },
                                }}
                                onRowClick={(params) => handleAddButtonClick(params)()}
                            />
                        </CardContent>
                    </Card>
                </Popper>
            </Box>
        </ClickAwayListener>
    );
}

ProductPicker.propTypes = {
    sx: Box?.propTypes?.sx,
    onAddProduct: PropTypes.func,
    onRemoveProduct: PropTypes.func,
    placeholder: PropTypes.string,
    SearchBarProps: PropTypes.any,
    value: PropTypes.any,
};
