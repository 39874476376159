import { createTheme } from '@mui/material/styles';
import { LinkBehavior } from './components/LinkBehavior/LinkBehavior';
import SalisburyBoldTtf from './assets/fonts/SalisburyBold.ttf';

export const Purple = '#A733B1';
export const Alert = '#D62E4F';
export const Alert40 = '#EFABB9';
export const Alert70 = '#E26C83';
export const Aqua = '#34EDEE';
export const Fog = '#E4E4E4';
export const LightGray = '#909090';
export const Midnight = '#24303C';
export const Midnight2 = '#FBFBFC';
export const Midnight20 = '#CCD6DB';
export const Midnight4 = '#F5F7F8';
export const Midnight50 = '#8099A4';
export const Midnight70 = '#4D7080';
export const Misty = '#839597';
export const Mustard = '#EFD758';
export const Mustard20 = '#C6B34E';
export const SoftBG = '#F4F4F4';
export const Teal = '#5C7B7D';
export const Tide = '#3A5157';
export const skyBlue = '#64CCF8';
export const White = '#FFF';
export const BorderRadius = '16px';
export const BoxShadow = '1px 1px 30px 0px rgba(0, 0, 0, 0.15)';
export const sanSarifFont = 'Inter';
export const sarifFont = 'SalisburyBold';

// Create a theme instance.
const theme = createTheme({
    typography: {
        fontFamily: sanSarifFont,
    },
    palette: {
        /**
         * custom colors to allow Button color="white"
         */
        review: {
            main: Purple,
            contrastText: White,
        },
        approved: {
            main: skyBlue,
            contrastText: Midnight,
        },
        cancelled: {
            main: Alert,
            contrastText: Midnight,
        },
        shipped: {
            main: Mustard,
            contrastText: Midnight,
        },
        complete: {
            main: Aqua,
            contrastText: Midnight,
        },
        destructive: {
            main: Alert,
            contrastText: Midnight,
        },
        white: {
            main: White,
            contrastText: Midnight,
        },
        teal: {
            main: Teal,
            contrastText: Midnight,
        },
        /**
         * end
         */

        background: {
            default: White,
        },
        shopify: {
            primary: '#95bf47',
        },
        common: {
            buttonPrimary: 'rgb(31, 101, 159)',
            borderColor: '#9095A1',
            borderColorHover: '#6F7787',
            borderColorFocus: '#9095A1',
            card: { greyBackgroundColor: '#DEE1E6' },
            lightGrey: '#f3f4f6',
            mediumGrey: '#a2a3a3',
            removeColor: '#de3b40',
            blueTypography: '#379ae6',
            green: '#62CD14',
            red: '#CD6214',
            white: '#ffffff',
            selectOptionSuccess: '#A0CDB5',
            // New design colors
            Alert,
            Alert40,
            Alert70,
            Fog,
            LightGray,
            Midnight,
            Midnight2,
            Midnight20,
            Midnight4,
            Midnight50,
            Midnight70,
            Misty,
            SoftBG,
            Teal,
            Tide,
            White,
        },
        text: {
            primary: Midnight,
        },
        primary: {
            main: '#1f659f',
            light: '#379ae6',
        },
        appBackground: {
            main: '#f8f9fa',
        },
        textField: {
            background: '#f3f4f7',
        },
    },
    custom: {
        boxShadow: BoxShadow,
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: BorderRadius,
                    boxShadow: BoxShadow,
                },
            },
        },

        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f3f4f6',
                    color: '#000000',
                },
            },
            variants: [
                {
                    props: { variant: 'retail' },
                    style: {
                        height: 27,
                    },
                },
            ],
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    color: Midnight,
                    paddingLeft: '24px',
                },
                root: {
                    borderRadius: '100px',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: Misty,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: Fog,
                    },
                },
            },
        },
        MuiMenuItem: {
            variants: [
                {
                    props: { variant: 'placeholder' },
                    style: {
                        color: Fog,
                    },
                },
            ],
        },
        MuiBadge: {
            variants: [
                {
                    props: { variant: 'cart' },
                    style: {
                        '& .MuiBadge-badge': {
                            color: White,
                            backgroundColor: Midnight,
                            width: 16,
                            height: 16,
                            minWidth: 16,
                            fontSize: 9,
                            fontWeight: 'bold',
                        },
                    },
                },
            ],
        },
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: "SalisburyBold";
                    src: local("SalisburyBold"), url(${SalisburyBoldTtf});
                }
            `,
        },
        MuiToolbar: {
            variants: [
                {
                    props: { variant: 'retail' },
                    style: {
                        height: 27,
                        minHeight: 'unset',
                    },
                },
            ],
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '& .evenRow': {
                        backgroundColor: 'whitesmoke',
                    },
                    '& .evenRow--blue': {
                        backgroundColor: '#D2DFF7',
                    },
                    '& .oddRow': {
                        backgroundColor: 'white',
                    },
                    '& .thatch-table-header--blue': {
                        color: '#00659F',
                        fontWeight: 'bold',
                    },
                },
            },
        },
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            },
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
        MuiButton: {
            styleOverrides: {
                text: {
                    textTransform: 'none',
                },
            },
            variants: [
                {
                    props: { size: 'micro' },
                    style: {
                        color: 'inherit',
                        fontSize: '.75rem',
                        paddingBottom: 0,
                        paddingTop: 0,
                    },
                },
                {
                    props: { variant: 'underlined' },
                    style: {
                        color: 'inherit',
                        fontSize: '.75rem',
                        textDecoration: 'underline',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            '& .MuiButton-label': {
                                textDecoration: 'underline',
                            },
                        },
                    },
                },
            ],
        },
        MuiChip: {
            variants: [
                {
                    props: { variant: 'orderStatus' },
                    style: {
                        fontSize: 10,
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                        letterSpacing: 2,
                        textTransform: 'uppercase',
                        '& .MuiChip-icon': {
                            marginLeft: '12px',
                            marginRight: '-4px',
                        },
                    },
                },
                {
                    props: { variant: 'outlined' },
                    style: {
                        fontSize: 10,
                        fontWeight: 700,
                        letterSpacing: 2,
                        textTransform: 'uppercase',
                        backgroundColor: SoftBG,
                    },
                },
            ],
        },
        MuiOutlinedInput: {
            variants: [
                {
                    props: { size: 'micro' },
                    style: {
                        '.MuiInputBase-input': {
                            color: 'inherit',
                            fontSize: '.75rem',
                            padding: '4px 7px',
                        },
                    },
                },
            ],
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: Midnight,
                    '&.Mui-checked': {
                        color: 'white',
                        '& .MuiSvgIcon-root': {
                            fill: Midnight,
                        },
                    },
                    '&.Mui-disabled': {
                        color: White,
                        '& .MuiSvgIcon-root': {
                            fill: Fog,
                        },
                    },
                },
                indeterminate: {
                    color: `${Midnight}`,
                },
            },
        },
        MuiListItemButton: {
            variants: [
                {
                    props: { variant: 'custom' },
                    style: {
                        padding: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1.5,
                        borderRadius: '8px',
                        backgroundColor: (props) => (props.isSelected ? Tide : 'transparent'),
                        '&:hover': {
                            backgroundColor: (props) => (props.isSelected ? Tide : 'transparent'),
                        },
                        '& .MuiSvgIcon-root': {
                            color: (props) => (props.isSelected ? White : Teal),
                            width: 32,
                            height: 32,
                        },
                        '& .MuiTypography-root': {
                            color: White,
                            fontWeight: 700,
                        },
                    },
                },
            ],
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '100px',
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: Fog,
                            borderRadius: 'inherit',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: Misty,
                            borderRadius: 'inherit',
                        },
                        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                            borderColor: Alert,
                            borderRadius: 'inherit',
                        },
                        paddingLeft: '0px',
                        '& .MuiOutlinedInput-input': {
                            paddingLeft: '24px',
                        },
                        '& .MuiInputBase-multiline': {
                            borderRadius: '16px',
                        },
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: '#f4f4f4',
                    },
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    display: 'p',
                    displayXl: 'h1',
                    displayLg: 'h1',
                    displayMd: 'h1',
                    displaySm: 'h1',
                    displayXs: 'h1',
                    displayTiny: 'p',
                    text2Xl: 'p',
                    textXl: 'p',
                    textLg: 'p',
                    textMd: 'p',
                    textSm: 'p',
                    textXs: 'p',
                    textTiny: 'p',
                    textMicro: 'p',
                },
            },
            variants: [
                {
                    props: { variant: 'display' },
                    style: {
                        fontFamily: sarifFont,
                    },
                },
                {
                    props: { variant: 'displayXl' },
                    style: {
                        fontFamily: sarifFont,
                        fontSize: 72,
                        lineHeight: '88px',
                    },
                },
                {
                    props: { variant: 'displayLg' },
                    style: {
                        fontFamily: sarifFont,
                        fontSize: 64,
                        lineHeight: '88px',
                    },
                },
                {
                    props: { variant: 'displayMd' },
                    style: {
                        fontFamily: sarifFont,
                        fontSize: 48,
                        lineHeight: '56px',
                    },
                },
                {
                    props: { variant: 'displaySm' },
                    style: {
                        fontFamily: sarifFont,
                        fontSize: 32,
                        lineHeight: '40px',
                    },
                },
                {
                    props: { variant: 'displayXs' },
                    style: {
                        fontFamily: sarifFont,
                        fontSize: 24,
                        lineHeight: '30px',
                    },
                },
                {
                    props: { variant: 'displayTiny' },
                    style: {
                        fontFamily: sarifFont,
                        fontSize: 16,
                        lineHeight: '30px',
                    },
                },
                {
                    props: { variant: 'text2Xl' },
                    style: {
                        fontSize: 32,
                        lineHeight: '40px',
                    },
                },
                {
                    props: { variant: 'textXl' },
                    style: {
                        fontSize: 24,
                        lineHeight: '32px',
                    },
                },
                {
                    props: { variant: 'textLg' },
                    style: {
                        fontSize: 20,
                        lineHeight: '26px',
                    },
                },
                {
                    props: { variant: 'textMd' },
                    style: {
                        fontSize: 16,
                        lineHeight: '22px',
                    },
                },
                {
                    props: { variant: 'textSm' },
                    style: {
                        fontSize: 14,
                        lineHeight: '18px',
                    },
                },
                {
                    props: { variant: 'textXs' },
                    style: {
                        fontSize: 12,
                        lineHeight: '16px',
                    },
                },
                {
                    props: { variant: 'textTiny' },
                    style: {
                        fontSize: 10,
                        lineHeight: '14px',
                    },
                },
                {
                    props: { variant: 'textMicro' },
                    style: {
                        fontSize: 8,
                        lineHeight: '12px',
                    },
                },
                {
                    props: { variant: 'label' },
                    style: {
                        color: Teal,
                        fontSize: '10px',
                        fontWeight: 700,
                        letterSpacing: '2px',
                        lineHeight: 'normal',
                        textTransform: 'uppercase',
                    },
                },
            ],
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: BorderRadius,
                    boxShadow: BoxShadow,
                },
            },
        },
    },
});

export default theme;
