import React, { useState } from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import EditableThatchAvatar from '../../ThatchAvatar/EditableThatchAvatar.jsx';
import ThatchAvatar from '../../ThatchAvatar/ThatchAvatar.jsx';
import Typography from '@mui/material/Typography';
import { Teal } from '../../../theme.js';
import * as api from '../../../api.js';
import { useError } from '../../Error/ErrorProvider.jsx';

export default function UserProfileHeader(props) {
    const { user, businessName, isEditable = false } = props;
    const [imgSrc, setImgSrc] = useState(user?.profileImageUrl);
    const { handleError } = useError();

    const updateProfileImage = async (image) => {
        try {
            await api.updateUser({ ...user, profileImageUrl: image }, {});
        } catch (error) {
            handleError(error);
        }
        setImgSrc(image);
    };

    const formattedName = () => {
        return user.firstName + ' ' + user.lastName;
    };

    return (
        <Card>
            <Stack direction="row" gap={2} sx={{ padding: 3, alignItems: 'center' }}>
                {isEditable ? (
                    <EditableThatchAvatar variant="user" src={imgSrc} onImageChange={updateProfileImage} />
                ) : (
                    <ThatchAvatar variant="user" src={user?.profileImageUrl} />
                )}

                <Stack>
                    <Typography variant="displayXs">{formattedName()}</Typography>
                    <Typography variant="textMd" sx={{ color: Teal }}>
                        {businessName}
                    </Typography>
                </Stack>
            </Stack>
        </Card>
    );
}

UserProfileHeader.propTypes = {
    user: PropTypes.any,
    businessName: PropTypes.string,
    onImageChange: PropTypes.func,
    isEditable: PropTypes.bool,
};
