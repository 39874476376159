import AssessmentIcon from '@mui/icons-material/Assessment';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StoreIcon from '@mui/icons-material/Store';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Outlet, useLocation, useMatches, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

const mobileDrawerWidth = 56;
const drawerWidth = 240;

const MobileFriendlyListItemIcon = ({ children, ...rest }) => (
    <ListItemIcon sx={{ minWidth: { xs: 'unset', lg: 56 } }} {...rest}>
        {children}
    </ListItemIcon>
);
MobileFriendlyListItemIcon.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const MobileFriendlyListItemText = ({ ...rest }) => (
    <ListItemText sx={{ display: { xs: 'none', lg: 'unset' } }} {...rest} />
);

export default function BrandLayout() {
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const navigate = useNavigate();
    const location = useLocation();
    const flags = useFlags();
    const { t } = useTranslation();
    const matches = useMatches();
    const drawerlessRoutes = ['BrandCreateOrderDetails', 'CreateProduct', 'EditProduct'];
    const isDrawerlessRoute = matches.some(({ id }) => {
        return drawerlessRoutes.includes(id);
    });

    React.useEffect(() => {
        // Update selectedIndex based on the current route
        const paths = location.pathname.split('/'); // Split the pathname into segments
        const mainPath = paths[2];
        switch (mainPath) {
            case 'new-order':
                setSelectedIndex(0);
                break;
            case 'products':
                setSelectedIndex(1);
                break;
            case 'orders':
                setSelectedIndex(2);
                break;
            case 'reports':
                setSelectedIndex(3);
                break;
            default:
                setSelectedIndex(-1); // No selection if the path doesn't match
                Sentry.captureMessage(`Unknown path: ${mainPath}`);
                break;
        }
    }, [location.pathname]);

    const handleIconButtonClick = (iconType, index) => () => {
        setSelectedIndex(index);
        switch (iconType) {
            case 'New Order':
                navigate('/brand/new-order');
                break;
            case 'Products':
                navigate('/brand/products');
                break;
            case 'Orders':
                navigate('/brand/orders');
                break;
            case 'Reports':
                navigate('/brand/reports');
                break;
            default:
                Sentry.captureMessage(`Unknown iconType: ${iconType}`);
                break;
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }} data-testid="brand-layout">
            {isDrawerlessRoute ? null : (
                <Drawer
                    sx={{
                        display: { xs: 'none', sm: 'initial' },
                        width: { xs: mobileDrawerWidth, lg: drawerWidth },
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: { xs: mobileDrawerWidth, lg: drawerWidth },
                            boxSizing: 'border-box',
                            position: 'fixed',
                            left: 0,
                            top: 'auto',
                            zIndex: 1,
                        },
                    }}
                    variant="permanent"
                    anchor="left">
                    <List component="nav">
                        {!flags.newOrder ? null : (
                            <ListItemButton
                                selected={selectedIndex === 0}
                                onClick={handleIconButtonClick('New Order', 0)}>
                                <MobileFriendlyListItemIcon>
                                    <AddIcon />
                                </MobileFriendlyListItemIcon>
                                <MobileFriendlyListItemText primary={t('BrandCreateOrder.title')} />
                            </ListItemButton>
                        )}
                        <ListItemButton selected={selectedIndex === 2} onClick={handleIconButtonClick('Orders', 2)}>
                            <MobileFriendlyListItemIcon>
                                <LocalShippingIcon />
                            </MobileFriendlyListItemIcon>
                            <MobileFriendlyListItemText primary="Orders" />
                        </ListItemButton>
                        <ListItemButton selected={selectedIndex === 1} onClick={handleIconButtonClick('Products', 1)}>
                            <MobileFriendlyListItemIcon>
                                <StoreIcon />
                            </MobileFriendlyListItemIcon>
                            <MobileFriendlyListItemText primary="Products" />
                        </ListItemButton>
                        <ListItemButton
                            disabled
                            selected={selectedIndex === 3}
                            onClick={handleIconButtonClick('Reports', 3)}>
                            <MobileFriendlyListItemIcon>
                                <AssessmentIcon />
                            </MobileFriendlyListItemIcon>
                            <MobileFriendlyListItemText primary="Reports" />
                        </ListItemButton>
                    </List>
                    <Divider />
                </Drawer>
            )}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    p: { xs: 2, sm: 3 },
                    width: !isDrawerlessRoute
                        ? { xs: `calc(100% - ${mobileDrawerWidth}px)`, lg: `calc(100% - ${drawerWidth}px)` }
                        : '100%',
                }}>
                <Outlet />
            </Box>
        </Box>
    );
}
