import * as React from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Form, Link, useNavigation } from 'react-router-dom';
import PropTypes from 'prop-types';
import PhoneInput from '../../components/PhoneInput/PhoneInput';
import BusinessTypeSelect from './BusinessTypeSelect';
import StateSelect from '../../components/StateSelect/StateSelect';
import { EmptyAddress } from '../../helpers/EmptyAddress.jsx';
import LoadingButton from '../../components/LoadingButton/LoadingButton.jsx';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material';
import FormInputLabel from '../../components/FormInputLabel/FormInputLabel.jsx';

function BusinessForm(props) {
    const theme = useTheme();
    const { action, data, _id, businessId = '', method } = props;
    const { t } = useTranslation();
    const navigation = useNavigation();
    const isSaving = navigation.state === 'submitting';
    const formRowStyles = { display: 'flex', '& > :not(style)': { m: 1 } };
    const address = data?.addresses[0] ?? EmptyAddress(data?.name);
    const phone = address?.phones[0].primary;
    const email = address?.emails[0].default;

    const responsiveDirection = { xs: 'column', sm: 'row' };
    const responsiveStyle = {
        sx: {
            alignItems: {
                xs: 'flex-start',
                sm: 'center',
            },
            justifyContent: {
                xs: 'flex-start',
                sm: 'center',
            },
            width: '100%',
        },
    };

    return (
        <Form action={action} method={method}>
            <input hidden name="_id" value={_id} readOnly />
            <input hidden name="businessId" value={businessId} readOnly />
            <Stack gap={2}>
                <Stack gap={1} direction={responsiveDirection} sx={responsiveStyle}>
                    <Box sx={{ flex: 1 }}>
                        <FormInputLabel text={t('BusinessSetup.businessNameLabel')}>
                            <TextField
                                fullWidth
                                name="businessName"
                                placeholder={t('BusinessSetup.businessNameLabel')}
                                defaultValue={data?.name}
                            />
                        </FormInputLabel>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <FormInputLabel text={t('BusinessSetup.businessTypeLabel')}>
                            <BusinessTypeSelect
                                selectProps={{ name: 'businessType', fullWidth: true, required: true }}
                            />
                        </FormInputLabel>
                    </Box>
                </Stack>
                <Stack gap={1} direction={responsiveDirection} sx={responsiveStyle}>
                    <Box sx={{ flex: 1 }}>
                        <FormInputLabel text={t('BusinessSetup.businessAddressLabel')}>
                            <TextField
                                fullWidth
                                placeholder={t('BusinessSetup.businessAddressLabel')}
                                name="addressLine1"
                                defaultValue={address?.street1}
                            />
                        </FormInputLabel>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <FormInputLabel text={t('BusinessSetup.cityLabel')}>
                            <TextField
                                fullWidth
                                placeholder={t('BusinessSetup.cityPlaceholder')}
                                name="city"
                                defaultValue={address?.city}
                            />
                        </FormInputLabel>
                    </Box>
                </Stack>
                <Stack gap={1} direction={responsiveDirection} sx={responsiveStyle}>
                    <Box sx={{ flex: 1 }}>
                        <FormInputLabel text={t('BusinessSetup.statePlaceholder')}>
                            <StateSelect selectProps={{ name: 'state' }} defaultValue={address?.state} />
                        </FormInputLabel>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <FormInputLabel text={t('BusinessSetup.zipLabel')}>
                            <TextField
                                fullWidth
                                name="zip"
                                placeholder={t('BusinessSetup.zipPlaceholder')}
                                defaultValue={address?.postalCode}
                            />
                        </FormInputLabel>
                    </Box>
                    <input hidden name="countryCode" value="US" readOnly />
                </Stack>
                <Stack gap={1} direction={responsiveDirection} sx={responsiveStyle}>
                    <Box sx={{ flex: 1 }}>
                        <FormInputLabel text={t('BusinessSetup.businessEmailLabel')}>
                            <TextField
                                placeholder={t('BusinessSetup.businessEmailLabel')}
                                fullWidth
                                name="email"
                                defaultValue={email}
                            />
                        </FormInputLabel>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <FormInputLabel text={t('BusinessSetup.phoneLabel').toUpperCase()}>
                            <PhoneInput fullWidth name="phone" defaultValue={phone} />
                        </FormInputLabel>
                    </Box>
                </Stack>
                <Box sx={formRowStyles}>
                    <FormGroup>
                        <FormControlLabel
                            required
                            control={<Checkbox name="termsAgreement" />}
                            label={
                                <Box sx={{ display: 'inline' }}>
                                    <Typography sx={{ color: theme.palette.common.LightGray }} component="span">
                                        {t('BusinessSetup.termsOfUseText')}
                                    </Typography>
                                    <Link to="/terms-of-use" style={{ color: theme.palette.common.LightGray }}>
                                        {t('BusinessForm.termsOfUse')}
                                    </Link>
                                    <Typography sx={{ color: theme.palette.common.LightGray }} component="span">
                                        {' & '}
                                    </Typography>
                                    <Link to="/privacy-policy" style={{ color: theme.palette.common.LightGray }}>
                                        {t('BusinessForm.privacyPolicy')}
                                    </Link>
                                </Box>
                            }
                        />
                    </FormGroup>
                </Box>
                <LoadingButton isLoading={isSaving} type="submit" variant="contained" sx={{ width: '100%' }}>
                    {t('BusinessForm.submitButtonLabel')}
                </LoadingButton>
            </Stack>
        </Form>
    );
}

BusinessForm.propTypes = {
    action: PropTypes.string,
    data: PropTypes.any,
    _id: PropTypes.number,
    businessId: PropTypes.string,
    method: PropTypes.string,
};

export default BusinessForm;
