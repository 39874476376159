import AddIcon from '@mui/icons-material/AddCircleOutline';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function BrandDrawerListItems(props) {
    const { basePath, handleNavClick } = props;
    const { t } = useTranslation();
    const flags = useFlags();

    return (
        <>
            {!flags.newOrder ? null : (
                <ListItem disablePadding>
                    <ListItemButton onClick={handleNavClick(`${basePath}/new-order`)}>
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('BrandCreateOrder.title')} />
                    </ListItemButton>
                </ListItem>
            )}
            <ListItem disablePadding>
                <ListItemButton onClick={handleNavClick(`${basePath}/orders`)}>
                    <ListItemIcon>
                        <LocalShippingIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('ThatchAppBar.ordersLabel')} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={handleNavClick(`${basePath}/products`)}>
                    <ListItemIcon>
                        <ShoppingBagOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('ThatchAppBar.productsLabel')} />
                </ListItemButton>
            </ListItem>
        </>
    );
}

BrandDrawerListItems.propTypes = {
    basePath: PropTypes.string,
    handleNavClick: PropTypes.func,
};
