import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import BuildingsIcon from '../../icons/BuildingsIcon.jsx';
import { BoxShadow, Fog, SoftBG, Tide } from '../../theme.js';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import UserCircleIcon from '../../icons/UserCircleIcon.jsx';
import { getInitials } from '../../helpers/HelperFunctions.jsx'; // Example icon for the button

export default function ThatchAvatar({ src, alt, variant, sx, ...props }) {
    const [hasError, setHasError] = useState(_.isEmpty(src));

    const fallbackStyle = { width: '50%', height: '50%', color: Tide };

    useEffect(() => {
        setHasError(_.isEmpty(src));
    }, [src]);

    const renderFallback = () => {
        switch (variant) {
            case 'business':
                return <BuildingsIcon sx={fallbackStyle} />;
            case 'user':
                return <UserCircleIcon sx={fallbackStyle} />;
            default:
                return (
                    <Typography variant="textXs" sx={{ lineHeight: 1, fontWeight: 800 }}>
                        {getInitials(alt) || ''}
                    </Typography>
                );
        }
    };

    const borderRadiusValue = variant === 'business' ? '16px' : '50%';

    return (
        <Box
            sx={{
                width: 110,
                height: 110,
                position: 'relative',
                borderRadius: borderRadiusValue,
                overflow: 'visible',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: hasError ? Fog : 'transparent',
                border: `5px solid ${SoftBG}`,
                boxShadow: BoxShadow,
                ...sx,
            }}>
            {!hasError ? (
                <Avatar
                    {...props}
                    alt={alt}
                    src={src}
                    onError={() => setHasError(true)}
                    sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: borderRadiusValue,
                    }}
                />
            ) : (
                renderFallback()
            )}
        </Box>
    );
}

ThatchAvatar.propTypes = {
    variant: PropTypes.oneOf(['business', 'user']),
    src: PropTypes.string,
    alt: PropTypes.string,
    sx: PropTypes.object,
};
