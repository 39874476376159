import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { Form, useLoaderData } from 'react-router-dom';
import * as api from '../../../../api';
import { useCart } from '../../../../cart/useCart';
import Crumbs from '../../../../components/Crumbs/Crumbs.jsx';
import CustomImageGallery from '../../../../components/CustomImageGallery/CustomImageGallery.jsx';
import HTMLDisplay from '../../../../components/HTMLDisplay/HTMLDisplay.jsx';
import ProductQuantityTextField from '../../../../components/ProductQuantityTextField/ProductQuantityTextField.jsx';
import SideCart from '../../../cart/SideCart.jsx';
import ProductHeader from './ProductHeader.jsx';
import ProductVariantSelect from './ProductVariantSelect';
import { useProduct } from './useProduct';
import { useState } from 'react';
import FormInputLabel from '../../../../components/FormInputLabel/FormInputLabel.jsx';

export async function loader({ request, params }) {
    const product = await api.getProductById(params.productId, { signal: request.signal });
    const variants = product?.productVariants || [];
    //  filter out inactive variants
    product.productVariants = variants.filter((variant) => {
        return variant.active === true;
    });

    const businesses = await api.getBusinesses({ signal: request.signal });
    return {
        business: [...(businesses || [])].filter((b) => `${b.id}` === params.id).pop(),
        product,
    };
}

function ProductDetails() {
    const { t } = useTranslation();
    const [quantity, setQuantity] = React.useState(1);
    const { state, addItem, addProductRef } = useCart();
    const data = useLoaderData();
    const { business = {} } = data || {};
    const isRouteBusinessInCart = state?.business ? `${state?.business}` === `${business?.id}` : true;
    const [isAddingToCart, setIsAddingToCart] = useState(false);

    const {
        product = {},
        images = [],
        variantOptions = [],
        selectedOptions,
        setSelectedOptions,
        isOrderable,
    } = useProduct();

    const handleOnSelect = (event) => {
        const update = {
            ...selectedOptions,
            [event.target.name]: event.target.value,
        };
        if (event.target.value === '') {
            delete update[event.target.name];
        }
        setSelectedOptions(update);
    };

    const handleSubmit = (event) => {
        // TODO in the future handle this with an action flow
        event.preventDefault();

        setIsAddingToCart(true);
        setTimeout(() => {
            setIsAddingToCart(false); // simulate api call until eventually cart persists for UI button design
        }, 1000);

        const formData = new FormData(event.target);
        const item = [...formData.entries()].reduce((acc, [key, value]) => ({ ...acc, [key]: parseInt(value) }), {});
        item.business = business.id;
        item.businessId = business.businessId;
        item.wholesale = product?.wholesale;
        item.title = product?.title;
        item.images = product?.imageLinks?.length ? product?.imageLinks : images?.length ? images : data?.imageLinks;
        item.variantTags = product?.variantTags;
        item.mpn = product?.mpn;
        item.sku = product?.sku;
        addItem(item);
        addProductRef(data?.product);
    };

    const formWidth = 364;

    return (
        <Box sx={{ pb: 4 }}>
            <Box sx={{ px: { xs: 3, sm: 7 }, pr: { sm: 4.5 }, pt: { xs: 3, sm: 6 } }}>
                <ProductHeader business={data?.business} product={product} />
            </Box>
            <Box sx={{ px: { xs: 3, sm: 7 }, pt: 3, pb: 1 }}>
                <Crumbs />
            </Box>
            <Box>
                {isRouteBusinessInCart ? null : (
                    <Box sx={{ my: 3 }}>
                        <Alert severity="error">{t('ProductDetails.cartBusinessIdMismatch')}</Alert>
                    </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ p: 2, px: { xs: 3, sm: 7 }, maxWidth: { md: 1054 }, flexGrow: 1 }}>
                        <Stack sx={{ mb: 4 }} direction="row" flexWrap="wrap" gap={4}>
                            <Box
                                sx={{
                                    flex: '1 0 auto',
                                    maxWidth: { sm: images?.length ? 545 : 465 },
                                    width: '100%',
                                    height: '100%',
                                }}>
                                <CustomImageGallery
                                    images={images.map((src) => ({
                                        original: src,
                                        thumbnail: src,
                                    }))}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    gap: 3,
                                    flex: { xs: '1 0 auto' },
                                    maxWidth: formWidth,
                                }}>
                                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                    <Typography
                                        variant="textLg"
                                        sx={{
                                            lineHeight: 'normal',
                                            fontSize: '24px',
                                            fontWeight: 700,
                                        }}>
                                        {product?.title}
                                    </Typography>
                                    <Stack direction="row" gap={2} alignItems="flex-end">
                                        <Typography
                                            sx={{
                                                fontSize: '32px',
                                                color: 'common.Tide',
                                                fontWeight: 600,
                                                lineHeight: 'normal',
                                            }}>
                                            <NumericFormat
                                                prefix="$"
                                                displayType="text"
                                                value={product?.wholesale}
                                                thousandSeparator
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                color: 'common.Teal',
                                                fontWeight: 600,
                                                lineHeight: 'normal',
                                                pb: '6px',
                                            }}>
                                            {t('ProductHeader.msrpLabel')}{' '}
                                            <NumericFormat
                                                prefix="$"
                                                displayType="text"
                                                value={product?.msrp}
                                                thousandSeparator
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                        </Typography>
                                    </Stack>
                                </Box>
                                {variantOptions?.map((i) => (
                                    <Box key={i.name} sx={{ maxWidth: formWidth }}>
                                        <FormInputLabel
                                            text={i.name}
                                            sx={{ letterSpacing: '2.4px', fontSize: 12, fontWeight: 800 }}>
                                            <ProductVariantSelect
                                                fullWidth
                                                value={selectedOptions?.[i.name] ?? ''}
                                                name={i.name}
                                                variantId={i.name}
                                                options={i.values}
                                                onChange={handleOnSelect}
                                            />
                                        </FormInputLabel>
                                    </Box>
                                ))}
                                <Box>
                                    <Box>
                                        <Form method="put" onSubmit={handleSubmit}>
                                            {product.productId && (
                                                <input name="productId" hidden value={product.productId} readOnly />
                                            )}
                                            <input name="id" hidden value={product.id} readOnly />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    gap: 2,
                                                }}>
                                                <ProductQuantityTextField
                                                    quantity={quantity}
                                                    onChange={setQuantity}
                                                    sx={{ width: '83px' }}
                                                    inputProps={{
                                                        sx: {
                                                            px: 2,
                                                            py: 1.5,
                                                            fontWeight: 700,
                                                            textAlign: 'center',
                                                        },
                                                    }}
                                                />
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <Button
                                                        disabled={
                                                            !isOrderable || !isRouteBusinessInCart || isAddingToCart
                                                        }
                                                        type="submit"
                                                        variant="contained"
                                                        fullWidth>
                                                        {isAddingToCart
                                                            ? 'Adding to cart...'
                                                            : t('ProductDetails.addToCartLabel')}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Form>
                                    </Box>
                                </Box>
                            </Box>
                        </Stack>
                        <Box sx={{ borderRadius: 1, mb: 3 }}>
                            <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                                {t('ProductDetails.descriptionLabel')}
                            </Typography>
                            <Box sx={{ fontWeight: 400 }}>
                                <HTMLDisplay content={product.description || ''} />
                            </Box>
                        </Box>
                        {product?.specs?.length < 1 ? null : (
                            <>
                                <Divider sx={{ mb: 3 }} />
                                <Box>
                                    <Typography variant="textLg" sx={{ fontWeight: 700, mb: 1 }}>
                                        {t('ProductDetails.specsLabel')}
                                    </Typography>
                                    <Box>
                                        <Paper>
                                            <TableContainer>
                                                <Table>
                                                    <TableBody>
                                                        {product?.specs?.map((i) => (
                                                            <TableRow key={i.name}>
                                                                <TableCell
                                                                    align="center"
                                                                    sx={{ width: 200, maxWidth: 200 }}>
                                                                    {i.name}
                                                                </TableCell>
                                                                <TableCell align="left">{i.value}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                    <SideCart />
                </Box>
            </Box>
        </Box>
    );
}

export default ProductDetails;
