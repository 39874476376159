import * as React from 'react';
import BusinessLogo from '../../../../components/BusinessLogo';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

export default function ConnectionCard({ business }) {
    const { t } = useTranslation();
    const height = '130px';
    const imageWidth = height;
    const location = () => {
        const location = business.addresses[0];
        if (location) {
            return location.city + ', ' + location.state;
        }
        return '';
    };

    return (
        <Card sx={{ display: 'flex', height }} component="span">
            <Stack direction="row" alignItems="center">
                <Box>
                    <BusinessLogo sx={{ height, width: imageWidth }} business={business} />
                </Box>
                <Box component="span" sx={{ display: 'flex', pr: 1 }}>
                    <CardContent>
                        <Typography variant="h6" component="span" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                            {business.name}
                            <Typography
                                sx={{ fontSize: '15px', display: 'block', color: 'inherit' }}
                                component="span"
                                key={business}>
                                {t('Connections.Connection.locationLabel')}
                                {': '}
                                {location()}
                            </Typography>
                        </Typography>
                    </CardContent>
                </Box>
            </Stack>
        </Card>
    );
}

ConnectionCard.propTypes = {
    business: PropTypes.object.isRequired,
};
