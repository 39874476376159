import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useNavigation, useRouteLoaderData } from 'react-router-dom';
import { BusinessTypes } from '../../constants/BusinessTypes.jsx';
import { useIsCartRoute } from '../../hooks/useIsCartRoute.js';
import BrandDrawerListItems from './BrandDrawerListItems.jsx';
import IconButtonsSection from './components/IconButtonsSection.jsx';
import LogoSection from './components/LogoSection.jsx';
import DefaultThatchAppBar from './default/DefaultThatchAppBar.jsx';

const drawerWidth = 240;

function ThatchAppBar(props) {
    const { signOut, height = '50px' } = props;
    const data = useRouteLoaderData('root') || {};
    const [isClosing, setIsClosing] = React.useState(false);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();
    const { state } = useNavigation();
    const isLoading = state === 'loading';
    const { t } = useTranslation();
    const { business = {}, thatchUser: user } = data;
    const { type } = business;

    const isCartRoute = useIsCartRoute();

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const handleNavClick = (path) => () => {
        handleDrawerToggle();
        navigate(path);
    };

    const handleSignOutClick = () => {
        handleDrawerToggle();
        if (signOut) {
            signOut();
        }
    };

    if ((!type && isLoading) || isCartRoute) {
        return;
    }
    if (type === BusinessTypes.RETAIL) {
        return null;
    }
    if (!type) {
        return <DefaultThatchAppBar />;
    }
    const basePath = type === BusinessTypes.BRAND ? '/brand' : '/retail';
    const listItems = [
        {
            icon: SettingsIcon,
            text: t('ThatchAppBar.settingsLabel'),
            onClick: handleNavClick(`${basePath}/user-profile`),
        },
        {
            divider: true,
        },
        {
            icon: LogoutIcon,
            text: t('ThatchAppBar.signOutLabel'),
            onClick: handleSignOutClick,
        },
    ];
    return (
        <>
            <AppBar position="fixed" sx={{ height, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar
                    variant="dense"
                    sx={{
                        width: '100%',
                        height,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                    <LogoSection height={height} />
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <IconButtonsSection type={type} />
                    </Box>
                    <IconButton
                        sx={{ display: { sm: 'none' } }}
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerToggle}>
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="right"
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}>
                <Toolbar data-info="Toolbar-placeholder" />
                <Divider />
                <List component="nav" sx={{ display: 'flex', flexDirection: 'column', flex: '1 0 auto' }}>
                    <ListItem component="li" sx={{ display: 'flex' }}>
                        <Avatar alt={user?.firstName} src={user?.profileImageUrl} sx={{ height: 50, width: 50 }} />
                        <Box sx={{ px: 1 }}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                {business?.name}
                            </Typography>
                            <Typography variant="caption">{user?.username}</Typography>
                        </Box>
                    </ListItem>
                    <Divider />
                    <BrandDrawerListItems handleNavClick={handleNavClick} basePath={basePath} />
                    <ListItem disablePadding sx={{ flex: '1 0 auto' }} />
                    {listItems.map(({ divider, icon: Icon, text, onClick }, i) =>
                        divider ? (
                            <Divider key={i} />
                        ) : (
                            <ListItem disablePadding key={i}>
                                <ListItemButton onClick={onClick}>
                                    <ListItemIcon>
                                        <Icon />
                                    </ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>
                        )
                    )}
                </List>
            </Drawer>
            {/* Toolbar here is a placeholder for the fixed AppBar */}
            <Toolbar sx={{ minHeight: { sm: 50 } }} />
        </>
    );
}

ThatchAppBar.propTypes = {
    signOut: PropTypes.func,
    height: PropTypes.string,
};

export default ThatchAppBar;
