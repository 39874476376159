import { Form, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LoadingButton from '../LoadingButton/LoadingButton.jsx';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useError } from '../Error/ErrorProvider.jsx';
import * as api from '../../api.js';
import { signIn } from 'aws-amplify/auth';
import { validatePasswords } from '../../helpers/HelperFunctions.jsx';
import _ from 'lodash';

export default function InviteSignupForm() {
    const formRowStyles = { display: 'flex', '& > :not(style)': { m: 1 } };
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { handleError } = useError();
    const signature = useRef('');
    const inviteId = useRef('');
    const navigate = useNavigate();
    const handleSubmit = async () => {
        const body = {
            firstName,
            lastName,
            signature: signature.current,
            inviteId: inviteId.current,
            password,
        };

        setIsLoading(true);
        try {
            const response = await api.linkNewBusiness({ body });
            const username = response.usernameToInvite;
            await signIn({ username, password });
            navigate('/');
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleTextChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
        }
    };

    const handleClickShowPassword = (type) => {
        switch (type) {
            case 'password':
                setShowPassword(!showPassword);
                break;
            case 'confirmPassword':
                setShowConfirmPassword(!showConfirmPassword);
                break;
        }
    };

    const validateForm = () => {
        const validFirstName = firstName !== '';
        const validLastName = lastName !== '';
        const passwordErrors = validatePasswords({ password, confirmPassword });
        const validPassword = _.isEmpty(passwordErrors);
        setPasswordErrors(passwordErrors);
        const passwordsMatch = password === confirmPassword;
        setPasswordMismatch(!passwordsMatch);
        setSubmitEnabled(validFirstName && validLastName && validPassword && passwordsMatch);
    };

    useEffect(() => {
        const queryString = window.location.search;
        const queryParams = new URLSearchParams(queryString);
        signature.current = queryParams.get('signature');
        inviteId.current = queryParams.get('id');
    }, []);

    useEffect(() => {
        validateForm();
    }, [firstName, lastName, password, confirmPassword]);

    return (
        <Box>
            <Form>
                <Box sx={formRowStyles}>
                    <TextField required fullWidth name="firstName" label="First Name" onChange={handleTextChange} />
                    <TextField required fullWidth name="lastName" label="Last Name" onChange={handleTextChange} />
                </Box>
                <Box sx={formRowStyles}>
                    <TextField
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={handleTextChange}
                        error={passwordErrors.length > 0}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        name="passwordButton"
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword('password')}
                                        edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                {passwordErrors.length > 0 ? (
                    <Box>
                        <List>
                            {passwordErrors.map((error, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={error} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                ) : null}
                <Box sx={formRowStyles}>
                    <TextField
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        error={passwordMismatch}
                        onChange={handleTextChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword('confirmPassword')}
                                        edge="end">
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                    <LoadingButton
                        isLoading={isLoading}
                        disabled={!submitEnabled}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}>
                        Sign Up
                    </LoadingButton>
                </Box>
            </Form>
        </Box>
    );
}
