import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Typography from '@mui/material/Typography';

function BrandSelect(props) {
    const { label, brands, onChange, selectProps, defaultValue = '' } = props;
    const { t } = useTranslation();
    const [value, setValue] = React.useState(_.isEmpty(defaultValue) ? t('BrandSelect.emptyLabel') : defaultValue);

    const handleChange = (event) => {
        setValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };
    return (
        <Box sx={{ maxWidth: 160 }}>
            <FormControl fullWidth>
                <InputLabel id="brand-select-label">{label}</InputLabel>
                <Select
                    label={label}
                    labelId="brand-select-label"
                    id="brand-select"
                    value={value}
                    onChange={handleChange}
                    {...selectProps}>
                    <MenuItem value={t('BrandSelect.emptyLabel')}>
                        <Typography
                            value={t('BrandSelect.emptyLabel')}
                            variant="textTiny"
                            sx={{ textTransform: 'uppercase', fontWeight: 'bold', pt: 0.5 }}>
                            {t('BrandSelect.emptyLabel')}
                        </Typography>
                    </MenuItem>
                    {brands.map((i) => (
                        <MenuItem key={i.businessId} value={i.businessId}>
                            <Typography
                                variant="textTiny"
                                sx={{ textTransform: 'uppercase', fontWeight: 'bold', pt: 0.5 }}>
                                {i.name}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

BrandSelect.propTypes = {
    label: PropTypes.string,
    brands: PropTypes.array,
    onChange: PropTypes.func,
    selectProps: PropTypes.any,
    defaultValue: PropTypes.string,
};

export default BrandSelect;
