import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo, useState } from 'react';
import { useFetcher, useNavigate, useNavigation, useRouteLoaderData, useSearchParams } from 'react-router-dom';
import * as api from '../../../../api';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import { createCurrencyColumn } from '../../../../helpers/HelperFunctions.jsx';
import { EmptyTable } from '../../../../components/EmptyTable/EmptyTable.jsx';
import ProductIcon from '../../../../components/ProductIcon.jsx';
import ProductSortBySelect from '../../../../components/ProductSortBySelect/ProductSortBySelect.jsx';
import shopifyIcon from '../../../../assets/shopify-logo.svg';
import Stack from '@mui/material/Stack';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as businessProvider from '../../../../providers/businessProvider.js';
import _, { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

const LIMIT = 10;

export async function loader({ request }) {
    const url = new URL(request.url);
    const offset = url.searchParams.get('offset') || 0;
    const sort = url.searchParams.get('sort') || 'title';
    const searchString = url.searchParams.get('searchString') || '';
    const active = url.searchParams.get('active') || '';

    const params = {
        query: {
            limit: LIMIT,
            offset,
            sort,
        },
    };

    if (searchString) {
        params.query.searchString = searchString;
    }

    if (active) {
        params.query.active = active;
    }

    const products = await api.getProducts(params);
    const { signal } = request;
    const business = await businessProvider.getBusiness({ signal });
    return {
        business,
        products,
    };
}

const mapProduct = (product) => ({
    id: product.id,
    image: product.imageLinks[0],
    MPN: product.mpn,
    ProductName: product.title,
    Year: new Date(product.createdAt).getFullYear(),
    MSRP: product.msrp,
    Wholesale: product.wholesale,
    RetailDefaultPrice: product.retailPrice,
    UPC: product.upc,
    Variants: product.productVariants ? product.productVariants.length : 0,
    Status: product.active ? 'Active' : 'Inactive',
});

export const setQuery = (passedSearchVal, passedHideVal, func) => {
    let params = {};
    if (_.isString(passedSearchVal)) {
        if (_.isEmpty(passedSearchVal)) {
            params = {};
        } else {
            params = { searchString: passedSearchVal };
        }
    }

    params.active = _.toString(passedHideVal);
    func(params);
};

export default function BrandProductTable() {
    const data = useRouteLoaderData('brand-products');
    const { business } = data;
    const linkedShops = business?.shopifySessions;

    const navigate = useNavigate();
    const { state } = useNavigation();
    const isLoading = state === 'loading';
    const [products, setProducts] = useState(data?.products?.rows?.map(mapProduct));
    const [hasMore, setHasMore] = useState(data?.products?.hasMore);
    const [offset, setOffset] = useState(data?.products?.offset || 0);
    const [search, setParams] = useSearchParams();
    const activeDefault = search.get('active') === 'true' || search.get('active') === null;
    const [activeFilter, setActiveFilter] = useState(activeDefault);
    const [searchValue, setSearchValue] = useState(search.get('searchString'));
    const sort = search.get('sort') || 'title';
    const flags = useFlags();
    const fetcher = useFetcher();
    const { t } = useTranslation();

    const queryProducts = useMemo(function () {
        return debounce(setQuery, 400);
    }, []);

    useEffect(() => {
        queryProducts(searchValue, activeFilter, setParams);
    }, [searchValue, activeFilter]);

    const handleSearchInputChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    const handleShowArchived = () => {
        const value = !activeFilter;
        setActiveFilter(value);
    };

    useEffect(() => {
        if (data?.products?.rows) {
            setProducts(data.products.rows.map(mapProduct));
            setHasMore(data.products.hasMore);
        }
    }, [data]);

    useEffect(() => {
        if (!fetcher.data || fetcher.state !== 'idle') {
            return;
        }
        const { products } = fetcher.data;
        if (products) {
            setHasMore(products.hasMore);
            setProducts((prev) => [...prev, ...products.rows?.map(mapProduct)]);
        }
    }, [fetcher]);

    const loadMoreRows = () => {
        if (!hasMore) {
            return;
        }
        const newOffset = offset + LIMIT;
        setOffset(newOffset);

        const queryString = new URLSearchParams();
        queryString.set('offset', newOffset);
        if (searchValue) {
            queryString.set('searchString', searchValue);
        }
        if (sort) {
            queryString.set('sort', sort);
        }
        const query = `/brand/products/?${queryString.toString()}`;
        fetcher.load(query);
    };

    const handleOnRowsScrollEnd = () => {
        loadMoreRows();
    };

    const columns = [
        {
            field: 'image',
            headerName: 'Image',
            width: 130,
            renderCell: (params) => (
                <ProductIcon src={params.value} objectFit="cover" sx={{ width: '45px', height: '45px' }} />
            ),
        },
        { field: 'MPN', headerName: 'MPN', width: 150 },
        { field: 'ProductName', headerName: 'Product Name', width: 300 },
        { field: 'Year', headerName: 'Year', width: 100 },
        createCurrencyColumn({ field: 'MSRP', headerName: 'MSRP', width: 120, currency: 'USD' }),
        createCurrencyColumn({ field: 'Wholesale', headerName: 'Wholesale', width: 120, currency: 'USD' }),
        createCurrencyColumn({ field: 'RetailDefaultPrice', headerName: 'Default', width: 180, currency: 'USD' }),
        { field: 'UPC', headerName: 'UPC', width: 150 },
        { field: 'Variants', headerName: 'Variants', width: 100 },
        { field: 'Status', headerName: 'Status', width: 110 },
    ];

    const navigateToCreateProductPage = () => {
        navigate('/brand/products/create-product');
    };

    const importShopify = () => {
        navigate('/brand/products/syncShopify');
    };

    const handleCellClick = (params, event) => {
        if (params.field === 'Variants') {
            // Stop the click event from propagating to the row click handler
            event.stopPropagation();
            // Navigate to the variants page for the clicked product
            navigate(`/brand/products/${params.id}/variants`);
        }
    };

    const handleRowClick = (params) => {
        navigate(`/brand/products/${params.id}`);
    };

    const handleOnSortByChange = (e) => {
        const sort = e.target.value;
        setOffset(0);
        setProducts([]);
        setParams({ sort });
    };

    const shouldShowShopifySync = () => {
        return flags.shopify && !_.isEmpty(linkedShops);
    };
    const showText = activeFilter ? t('BrandProductTable.showArchived') : t('BrandProductTable.hideArchived');
    return (
        <>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: 3,
                        mb: 2,
                    }}>
                    <SearchBar
                        onChange={handleSearchInputChange}
                        value={_.toString(searchValue)}
                        placeholder="Search products"
                        sx={{ maxWidth: 360 }}
                    />
                    <Button onClick={navigateToCreateProductPage} color="primary" variant="contained" size="xs">
                        {t('BrandProductTable.createProductBtn')}
                    </Button>
                    <Button
                        size={'small'}
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        id="show-archive"
                        component="span"
                        onClick={handleShowArchived}>
                        {showText}
                    </Button>
                    {shouldShowShopifySync() ? (
                        <Button onClick={importShopify} sx={{ color: 'black', fontWeight: 'bold' }}>
                            <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                                <Box>
                                    <img src={shopifyIcon} alt="Shopify" style={{ width: '30px', height: '30px' }} />
                                </Box>
                                {t('BrandProductTable.syncWithShopify')}
                            </Stack>
                        </Button>
                    ) : null}
                    <Box sx={{ flex: '1 0 auto', display: 'flex', justifyContent: 'flex-end' }}>
                        <ProductSortBySelect value={sort} label={'Sort by'} onChange={handleOnSortByChange} />
                    </Box>
                </Box>
                <DataGridPro
                    columns={columns}
                    rows={products}
                    loading={isLoading}
                    scrollEndThreshold={100}
                    onRowsScrollEnd={handleOnRowsScrollEnd}
                    slots={{
                        loadingOverlay: LinearProgress,
                        noRowsOverlay: () => <EmptyTable message="No products found" />,
                    }}
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow')}
                    onRowClick={(params) => {
                        handleRowClick(params);
                    }}
                    onCellClick={(params, event) => handleCellClick(params, event)}
                    autoHeight
                    sx={{
                        '& .MuiDataGrid-row': {
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                            outlineOffset: '0',
                            outline: 'none !important',
                        },
                    }}
                />
            </Box>
        </>
    );
}
