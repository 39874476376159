import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import thatchLogo from '../../../assets/ThatchLogo--black.svg';
import PropTypes from 'prop-types';

export default function DefaultThatchAppBar({ height }) {
    const navigate = useNavigate();
    return (
        <AppBar position="static">
            <Toolbar variant="dense" sx={{ height }}>
                <IconButton
                    onClick={() => navigate('/')}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}>
                    <img src={thatchLogo} alt="Thatch logo" style={{ width: '150px', height }} />
                </IconButton>
                <Box sx={{ flexGrow: 1 }}></Box>
            </Toolbar>
        </AppBar>
    );
}

DefaultThatchAppBar.propTypes = {
    height: PropTypes.string,
};
