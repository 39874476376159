import Box from '@mui/material/Box';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InviteSignupForm from '../../components/InviteSignupForm/InviteSignupForm.jsx';
import ThatchAppBar from '../../components/ThatchAppBar/ThatchAppBar.jsx';
import { useEffect } from 'react';
import * as authProvider from '../../providers/auth.js';
import * as businessProvider from '../../providers/businessProvider.js';
import * as userProvider from '../../providers/userProvider.js';

export default function InviteSignup() {
    useEffect(() => {
        const clearCacheAndSignOut = async () => {
            await authProvider.signOut();
            businessProvider.clearCache();
            userProvider.clearCache();
        };
        clearCacheAndSignOut();
    }, []);
    return (
        <Box>
            <ThatchAppBar />
            <Container maxWidth="sm">
                <Box sx={{ py: { xs: 4, sm: 8, md: 10 } }}>
                    <Paper elevation={1}>
                        <Card>
                            <CardContent>
                                <Box>
                                    <Box sx={{ textAlign: 'center', mb: 4 }}>
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant="h5">Sign Up</Typography>
                                        </Box>
                                    </Box>
                                    <InviteSignupForm />
                                </Box>
                            </CardContent>
                        </Card>
                    </Paper>
                </Box>
            </Container>
        </Box>
    );
}
