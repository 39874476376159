import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ConnectionLink from '../../retail/connections/components/ConnectionLink.jsx';
import NoConnectionsFound from '../../retail/connections/components/NoConnectionsFound.jsx';
import { useBusinesses } from '../../../hooks/useBusinesses.js';

function BrandConnections({ routeId, businessType }) {
    const { t } = useTranslation();
    const [businesses] = useBusinesses(routeId);
    // TODO handle error
    return (
        <Container sx={{ my: 4 }}>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4">{t('Connections.title')}</Typography>
            </Box>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={4}>
                    {businesses.map((business) => (
                        <Grid key={business.id} item xs={12} sm={6} md={4}>
                            <ConnectionLink businessType={businessType} business={business} />
                        </Grid>
                    ))}
                    {(!businesses || !businesses.length) && <NoConnectionsFound businessType={businessType} />}
                </Grid>
            </Box>
        </Container>
    );
}

export default BrandConnections;

BrandConnections.propTypes = {
    routeId: PropTypes.string,
    businessType: PropTypes.string,
};
