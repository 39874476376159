import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputLabel,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteLoaderData } from 'react-router-dom';
import CircleXIcon from '../../icons/CircleXIcon';
import { Alert, Midnight } from '../../theme';
import AddressSelect from '../AddressSelect/AddressSelect';
import PhoneInput from '../PhoneInput/PhoneInput';
import StateSelect from '../StateSelect/StateSelect';

export default function EditShippingAddressDialog({ address, open, onChange, onClose }) {
    const { t } = useTranslation();
    const root = useRouteLoaderData('root');
    const [selectedAddress, setSelectedAddress] = useState({ ...(address || {}) });

    useEffect(() => {
        if (open) {
            setSelectedAddress(address);
        }
    }, [open]);

    const handleAddressSelect = (e, address) => {
        const update = {
            ...address,
            label: address.label || root?.business?.name + (address?.name ? ' | ' + address?.name : ''),
        };
        setSelectedAddress(update);
    };

    const handleSubmit = (event) => {
        if (onChange) {
            onChange(event, { ...selectedAddress });
        }
        onClose();
    };

    const handleUpdateAddress = (e) => {
        setSelectedAddress((prev) => {
            if (e.target.name === 'email') {
                return {
                    ...prev,
                    emails: [{ ...prev?.emails?.[0], default: e.target.value }],
                };
            }
            if (e.target.name === 'phone') {
                return {
                    ...prev,
                    phones: [{ ...prev?.phones?.[0], primary: e.target.value }],
                };
            }
            if (e.target.name === 'phone2') {
                return {
                    ...prev,
                    phones: [{ ...prev?.phones?.[0], secondary: e.target.value }],
                };
            }
            return {
                ...prev,
                [e.target.name]: e.target.value,
            };
        });
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                        {t('EditShippingAddressDialog.title')}
                    </Typography>
                    <IconButton onClick={onClose} aria-label="close">
                        <CircleXIcon sx={{ color: Midnight, width: 32, height: 32 }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Box sx={{ maxWidth: 248, mt: '1px', mb: 1 }}>
                        <AddressSelect
                            placeholder={t('EditShippingAddressDialog.selectNewAddressLabel')}
                            onChange={handleAddressSelect}
                        />
                    </Box>
                    <Stack gap={1}>
                        <InputLabel htmlFor="addressLabel">
                            <Typography variant="label" sx={{ fontSize: 14 }}>
                                {t('EditShippingAddressDialog.addressLabelLabel')}
                            </Typography>
                        </InputLabel>
                        <TextField
                            onChange={handleUpdateAddress}
                            id="addressLabel"
                            name="label"
                            fullWidth
                            variant="outlined"
                            value={selectedAddress?.label}
                        />
                    </Stack>
                    <Stack direction="row" gap={2} flexWrap="wrap">
                        <Stack gap={1} sx={{ maxWidth: { sm: 300 }, width: '100%' }}>
                            <InputLabel htmlFor="street1">
                                <Typography variant="label" sx={{ fontSize: 14 }}>
                                    {t('EditShippingAddressDialog.addressLabel')}
                                </Typography>
                            </InputLabel>
                            <TextField
                                onChange={handleUpdateAddress}
                                id="street1"
                                name="street1"
                                fullWidth
                                variant="outlined"
                                value={selectedAddress?.street1}
                            />
                        </Stack>
                        <Stack gap={1} sx={{ flexGrow: '1' }}>
                            <InputLabel htmlFor="city">
                                <Typography variant="label" sx={{ fontSize: 14 }}>
                                    {t('EditShippingAddressDialog.cityLabel')}
                                </Typography>
                            </InputLabel>
                            <TextField
                                onChange={handleUpdateAddress}
                                id="city"
                                name="city"
                                fullWidth
                                variant="outlined"
                                value={selectedAddress?.city}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="row" gap={2}>
                        <Stack gap={1} sx={{ maxWidth: 225, width: '100% ' }}>
                            <InputLabel htmlFor="state">
                                <Typography variant="label" sx={{ fontSize: 14 }}>
                                    {t('EditShippingAddressDialog.stateLabel')}
                                </Typography>
                            </InputLabel>
                            <StateSelect
                                onChange={handleUpdateAddress}
                                id="state"
                                selectProps={{ name: 'state' }}
                                value={selectedAddress?.state}
                            />
                        </Stack>
                        <Stack gap={1} sx={{ maxWidth: 150, width: '100% ' }}>
                            <InputLabel htmlFor="postalCode">
                                <Typography variant="label" sx={{ fontSize: 14 }}>
                                    {t('EditShippingAddressDialog.zipLabel')}
                                </Typography>
                            </InputLabel>
                            <TextField
                                id="postalCode"
                                name="postalCode"
                                onChange={handleUpdateAddress}
                                fullWidth
                                variant="outlined"
                                value={selectedAddress?.postalCode}
                            />
                        </Stack>
                    </Stack>
                    <Stack gap={1} sx={{ maxWidth: 338, width: '100%' }}>
                        <InputLabel htmlFor="email">
                            <Typography variant="label" sx={{ fontSize: 14 }}>
                                {t('EditShippingAddressDialog.emailLabel')}
                            </Typography>
                        </InputLabel>
                        <TextField
                            id="email"
                            name="email"
                            onChange={handleUpdateAddress}
                            fullWidth
                            variant="outlined"
                            value={selectedAddress?.emails?.[0].default}
                        />
                    </Stack>
                    <Stack direction="row" gap={2} flexWrap="wrap">
                        <Stack gap={1}>
                            <InputLabel htmlFor="phone">
                                <Typography variant="label" sx={{ fontSize: 14 }}>
                                    {t('EditShippingAddressDialog.phoneLabel')}{' '}
                                    {
                                        <Typography component="span" sx={{ color: Alert }}>
                                            *
                                        </Typography>
                                    }
                                </Typography>
                            </InputLabel>
                            <PhoneInput
                                id="phone"
                                onChange={(value) => handleUpdateAddress({ target: { name: 'phone', value } })}
                                fullWidth
                                value={selectedAddress?.phones?.[0]?.primary}
                            />
                        </Stack>
                        <Stack gap={1}>
                            <InputLabel htmlFor="phone2">
                                <Typography variant="label" sx={{ fontSize: 14 }}>
                                    {t('EditShippingAddressDialog.secondaryPhoneLabel')}
                                </Typography>
                            </InputLabel>
                            <PhoneInput
                                id="phone2"
                                onChange={(value) => handleUpdateAddress({ target: { name: 'phone2', value } })}
                                fullWidth
                                value={selectedAddress?.phones?.[0]?.secondary}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={handleSubmit}>
                    {t('EditShippingAddressDialog.submitButton')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

EditShippingAddressDialog.propTypes = {
    address: PropTypes.any,
    open: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};
